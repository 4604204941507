import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import OnboardingAlert from 'components/UI/OnboardingAlert';

import { ETutorialStatus } from 'constants/graphqlTypes';
import { TEST_SPOTLIGHT_ITEM } from 'constants/aqa/addPick';
import { IUseSpotlights } from 'graphQL/spotlights/hook';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';
import SliderInteractive from 'components/SliderInteractive';

import { StyledCardWrap } from 'components/Sliders/styled';

import { StyledSlide, StyledHeader, StyledWrapper, StyledSliderContainer, StyledAlertWrap } from './styled';

import Card from './Card';

interface ISpotlightsProps {
  fontColor: string;
  spotlightsData: IUseSpotlights;
  hiddenCards: string[];
  setIndex: Dispatch<SetStateAction<number>>;
  setHiddenCards: Dispatch<SetStateAction<string[]>>;
}

const SPOTLIGHT_CHANGE_DELAY = 10000;

const Spotlights: FC<ISpotlightsProps> = ({ setIndex, spotlightsData, fontColor, hiddenCards, setHiddenCards }) => {
  const { title, showSelectPicksBtn, hasSpotlights, cards } = spotlightsData;
  const { isSpotlightIntroCompleted, loading, tutorialStatus } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({ isSpotlightIntroCompleted: true });
  const [shouldPauseAutoplay, setShouldPauseAutoplay] = useState(false);

  const [isAnimationStarted, setAnimationStarted] = useState(false);
  const hideReactionModal = () => {
    setAnimationStarted(() => {
      setTimeout(() => {
        setAnimationStarted(false);
      }, 50);

      return true;
    });
  };

  const isSpotlightsIntro =
    !isSpotlightIntroCompleted &&
    !loading &&
    !hasSpotlights &&
    showSelectPicksBtn &&
    tutorialStatus === ETutorialStatus.Finished;

  const [slideIndex, setSlideIndex] = useState(0);
  const [autoplayOptions, setAutoplayOptions] = useState({
    delay: SPOTLIGHT_CHANGE_DELAY,
    disableOnInteraction: false,
    stopOnLastSlide: true,
    reverseDirection: false,
  });

  const changeSlidesDirection = (isReverse: boolean) =>
    setAutoplayOptions((options) => ({ ...options, reverseDirection: isReverse }));

  const changeOptions = (index: number) => {
    if (index === cards.length - 1) {
      changeSlidesDirection(true);
    } else if (index === 0) {
      changeSlidesDirection(false);
    }
    setSlideIndex(index);
  };

  return cards.length ? (
    <StyledWrapper>
      <StyledHeader>
        <span>{title}</span>
      </StyledHeader>
      {isSpotlightsIntro && (
        <StyledAlertWrap>
          <OnboardingAlert
            title="Choose picks to Spotlight"
            submitText="Got it"
            handleSubmit={() => updateUserSettings()}
          >
            Pin your favorite picks to the top of your profile
          </OnboardingAlert>
        </StyledAlertWrap>
      )}
      <div id="spotlight-container">
        {cards.length === 1 ? (
          <StyledCardWrap hidePaddingTop>
            <StyledSlide isActive>
              <Card
                isSpotlightInFocus
                dataTestId={`${TEST_SPOTLIGHT_ITEM}-0`}
                card={cards[0]}
                fontColor={fontColor}
                isHidden={hiddenCards.includes(cards[0].id)}
                setHiddenCards={setHiddenCards}
              />
            </StyledSlide>
          </StyledCardWrap>
        ) : (
          <StyledSliderContainer>
            <SliderInteractive
              arrowsOffset={150}
              showBtnsSm
              spaceBetween={13}
              customSlidesPerView={1}
              centeredSlides
              onSlideChange={(index) => {
                setIndex(index);
                setAnimationStarted(false);
                changeOptions(index);
              }}
              fontColor={fontColor}
              onTransitionStart={hideReactionModal}
              onAnimationStart={hideReactionModal}
              autoplayOptions={autoplayOptions}
              isInfinite={false}
              isPrevDisabled={slideIndex === 0}
              isNextDisabled={slideIndex === cards.length - 1}
              shouldPauseAutoplay={shouldPauseAutoplay}
            >
              {cards.map((card, index) => {
                const isCurrentCardActive = slideIndex === index;
                return (
                  <SwiperSlide key={card.id}>
                    <StyledSlide isActive={isCurrentCardActive}>
                      <Card
                        isSpotlightInFocus={isCurrentCardActive}
                        dataTestId={`${TEST_SPOTLIGHT_ITEM}`}
                        card={card}
                        fontColor={fontColor}
                        isAnimationStarted={isAnimationStarted}
                        isHidden={hiddenCards.includes(card.id)}
                        setHiddenCards={setHiddenCards}
                        setShouldPauseAutoplay={setShouldPauseAutoplay}
                      />
                    </StyledSlide>
                  </SwiperSlide>
                );
              })}
            </SliderInteractive>
          </StyledSliderContainer>
        )}
      </div>
    </StyledWrapper>
  ) : null;
};

export default Spotlights;
