import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import { FONTS, COLORS, BREAKPOINTS } from '../../../styles/constants';
import {
  StyledPrivateLabel as ImportedStyledPrivateLabel,
  StyledCollaboratorsContainer as ImportedStyledCollaboratorsContainer,
} from '../BigCardV2/styled';
import { getLineHeight } from './helpers';

export const StyledReactionWrap = styled.div<{ disableLinks?: boolean }>`
  position: relative;

  a,
  .card,
  .card-link {
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
    user-select: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }
`;

export const StyledTitle = styled.p<{ fontSize: number; linesToTruncate: 2 | 3 }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ linesToTruncate }) => linesToTruncate};
  color: ${COLORS.white[100]};
  display: -webkit-box;
  font: 400 ${({ fontSize }) => fontSize}px / ${getLineHeight()}px ${FONTS.primary};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDate = styled.p<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 12px/140% ${FONTS.secondary};
  margin: 0;
`;

export const StyledWrap = styled.div<{
  bgColor: string;
  hasItems: boolean;
}>`
  background: ${({ bgColor, hasItems }) =>
    !hasItems
      ? 'linear-gradient(180deg, #666 0.01%, #989898 100%), linear-gradient(180deg, #404040 0%, #1A1A1A 100%)'
      : bgColor};
  border-radius: 8px;
  height: 236px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    height: 286px;
  }
`;

export const StyledInner = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  padding-bottom: 12px;
  box-sizing: border-box;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

export const StyledHead = styled.div`
  padding: 16px 16px 8px;
  position: relative;
`;

export const StyledImagesList = styled.ul<{ isMultiple: boolean }>`
  align-items: flex-end;
  margin: 0;
  gap: 8px;
  height: 100%;

  &.images-1 {
    padding: 0 12px;
    display: flex;
    justify-content: center;

    img {
      object-position: bottom center;
      flex: 1 1;
    }
  }

  &.images-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;

    img {
      flex-shrink: 0;

      &:first-child {
        justify-self: end;
        object-position: bottom right;
      }
      &:nth-child(2) {
        justify-self: start;
        object-position: bottom left;
      }
    }
  }

  &.images-3 {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;

    img {
      flex-shrink: 0;

      &:first-child {
        justify-self: end;
        object-position: bottom right;
      }
      &:nth-child(2) {
        justify-self: center;
      }

      &:nth-child(3) {
        justify-self: start;
        object-position: bottom left;
      }
    }
  }
`;

export const StyledImageItem = styled.img<{ imageRatio: number; itemsLength: number; has3lines: boolean }>`
  height: auto;
  object-position: bottom;
  object-fit: contain;
  overflow: hidden;
  position: relative;
  aspect-ratio: ${({ imageRatio }) => imageRatio};

  max-height: ${({ imageRatio, itemsLength, has3lines }) => {
    if (has3lines) {
      return '134px';
    }
    if (itemsLength < 3) {
      return imageRatio === 1 ? '132px' : '138px';
    }
    return imageRatio === 1 ? '124px' : '138px';
  }};

  max-width: ${({ imageRatio, itemsLength }) => {
    if (itemsLength < 3) {
      return imageRatio === 1 ? '132px' : '152px';
    }
    return imageRatio === 1 ? '124px' : '138px';
  }};

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: ${({ imageRatio, itemsLength, has3lines }) => {
      if (has3lines) {
        return '176px';
      }
      if (itemsLength < 3) {
        return imageRatio === 1 ? '172px' : '180px';
      }
      return imageRatio === 1 ? '164px' : '180px';
    }};

    max-width: ${({ imageRatio, itemsLength }) => {
      if (itemsLength < 3) {
        return imageRatio === 1 ? '172px' : '198px';
      }
      return imageRatio === 1 ? '164px' : '180px';
    }};
  }
`;

export const StyledTextWrap = styled.div<{ bgColor: string }>`
  border-radius: 8px;
  border: 1px solid rgba(24, 24, 0, 0.1);
  margin: 0 auto 17px;
  overflow: hidden;
`;

export const StyledTextsList = styled.ul<{ bgColor: string; size: number }>`
  background: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: ${({ size }) => `${size}px`};
  list-style: none;
  margin: 0;
  padding: 6.5%;
  width: ${({ size }) => `${size}px`};
`;

export const StyledTextItem = styled.li<{ opacity: number; color: string }>`
  color: ${({ color }) => color};
  line-height: 130%;
  opacity: ${({ opacity }) => opacity};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledListAuthors = styled.div<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 12px/140% ${FONTS.secondary};
  margin-left: 8px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StyledLabel = styled.span`
  background-color: ${COLORS.white.off[100]};
  border-radius: 4px;
  bottom: 12px;
  color: ${COLORS.brown.dark[100]};
  font: 400 12px/140% ${FONTS.secondary};
  left: 12px;
  padding: 4px 8px;
  position: absolute;
  width: fit-content;
`;

export const StyledPrivateLabel = styled(ImportedStyledPrivateLabel)`
  left: 8px;
  bottom: 8px;
`;

export const StyledCollaboratorsContainer = styled(ImportedStyledCollaboratorsContainer)`
  display: flex;
  padding: 4px 10px;
  left: 8px;
  bottom: 8px;
`;
export const StyledAvatarsWrap = styled.div`
  padding-left: 8px;
`;

export const StyledComingSoonText = styled.span`
  font: 400 14px/140% ${FONTS.secondary};
  color: ${COLORS.white[100]};
  position: absolute;
  top: 56%;

  left: 50%;
  transform: translate(-50%, -50%);
`;
