import React, { FC, useMemo } from 'react';

import { v4 } from 'uuid';

import { CardSchema } from 'constants/graphqlTypes';
import Grain from 'components/UI/Grain';
import Description from './Description';
import Footer from './Footer';

import { StyledTextBlock, StyledTextCard, StyledTextItem } from './styled';

interface IContentCardProps {
  item: CardSchema;
  titles: string[];
  bgColor: string;
}

const TextCardV2: FC<IContentCardProps> = ({ item, titles, bgColor }) => {
  const titlesWithKey = useMemo(
    () =>
      [...titles, ...titles, ...titles].map((cover) => ({
        cover,
        key: v4(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titles.length, titles.join(','), v4]
  );

  return (
    <StyledTextCard bgColor={bgColor}>
      <Description item={item} />
      <Grain />
      <StyledTextBlock bgColor={bgColor}>
        <ul>
          {titlesWithKey.map(({ cover, key }) => (
            <StyledTextItem key={key}>{cover}</StyledTextItem>
          ))}
        </ul>
      </StyledTextBlock>
      <Footer item={item} />
    </StyledTextCard>
  );
};

export default TextCardV2;
