import React, { FC } from 'react';

import { CardSchema, ImageSetSchema } from 'constants/graphqlTypes';
import useTheme from 'helpers/useTheme';
import Grain from 'components/UI/Grain';

import { generateCardStyles } from '../BigCard/Card/helpers/helpers';
import Description from './Description';

import Footer from './Footer';

import { StyledContentCard, StyledImage, StyledImageContainer, StyledShownImagesContainer } from './styled';

interface IBigCardContentProps {
  item: CardSchema;
  images: ImageSetSchema[];
  bgColor?: string;
}

const getKey = (url: string, index: number) => `${url}-${index}`;

const BigCardV2: FC<IBigCardContentProps> = ({ item, images, bgColor }) => {
  const card = generateCardStyles(item, images);

  const { gradientStart, gradientEnd } = card;
  const isDark = useTheme();

  const imagesUrls = images
    .map((image) => ({
      url: image?.images?.[0]?.url ?? '',
      aspectRatio: image?.images?.[0]?.aspectRatio ?? 1,
    }))
    .slice(0, 3)
    .reverse();

  const urlsToDisplay = imagesUrls?.length > 1 ? imagesUrls : Array(3).fill(imagesUrls[0]);

  return (
    <StyledContentCard isDark={isDark} gradientStart={gradientStart} gradientEnd={gradientEnd} bgColor={bgColor}>
      <Grain />
      <Description item={item} />
      <StyledShownImagesContainer>
        <div>
          {urlsToDisplay.map(({ url, aspectRatio }, index) => (
            <StyledImageContainer index={index} key={getKey(url, index)}>
              <StyledImage aspectRatio={aspectRatio} src={url} />
            </StyledImageContainer>
          ))}
        </div>
      </StyledShownImagesContainer>
      <Footer item={item} />
    </StyledContentCard>
  );
};

export default BigCardV2;
