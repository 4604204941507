import { styled } from 'linaria/lib/react';

import StyledButton from '../../UI/Button/styled';

import { COLORS, FONTS } from '../../../styles/constants';
import { BUTTON_STYLES } from '../../UI/Button/constants';

export const StyledInfoContainer = styled.div<{
  hasWhiteAvatar: boolean;
  hasPaddingBottom: boolean;
  removePaddingLeftRight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 64px ${({ removePaddingLeftRight }) => (removePaddingLeftRight ? 0 : 24)}px
    ${({ hasPaddingBottom }) => (hasPaddingBottom ? '48px' : 0)};

  .avatar {
    background-color: ${({ hasWhiteAvatar }) => (hasWhiteAvatar ? COLORS.white[10] : COLORS.brown.light[100])};
  }
`;

export const StyledFullName = styled.span`
  font: 400 32px/130% ${FONTS.primary};
  padding: 16px 0 4px 0;
  text-align: center;
  word-break: break-word;
`;

export const StyledUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  font: 14px/140% ${FONTS.secondary};
  text-align: center;
`;

export const StyledFollowButtonContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  padding-top: 16px;
`;

export const StyledText = styled.span<{ shouldBreak?: boolean }>`
  word-break: ${({ shouldBreak }) => (shouldBreak ? 'break-word' : 'normal')};
  font-weight: 400;
`;

export const StyledLink = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: ${FONTS.secondary};
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
  text-decoration: none;
`;

export const StyledSection = styled.section<{
  fontColor: string;
  fixedHeight: boolean;
  gradientStart?: string | null;
  gradientEnd?: string | null;
  isDark: boolean;
}>`
  color: ${({ fontColor }) => fontColor};
  min-height: ${({ fixedHeight }) => (fixedHeight ? '100dvh' : 'auto')};
  position: relative;
  background: ${({ gradientStart, isDark }) => gradientStart ?? (isDark ? COLORS.black[100] : COLORS.white[100])};
  transition: 0.5s all ease-in-out;

  &::before {
    background: ${({ gradientEnd }) =>
      `linear-gradient(${COLORS.transparent} 5%, ${gradientEnd ?? COLORS.black[60]} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: ${({ gradientStart }) => (gradientStart ? '1' : '0')};
  }
`;

export const StyledIcon = styled.span`
  display: inline-block;
  padding: 0 4px;
`;

export const StyledVerifiedIcon = styled.span`
  display: inline-flex;
  padding: 0 4px 0 6px;
  vertical-align: middle;
`;

export const StyledSubtitle = styled.span`
  font: 600 14px/140% ${FONTS.secondary};
`;

export const StyledVerificationWrap = styled.div`
  padding-top: 4px;
`;

export const StyledWrap = styled.span`
  flex-shrink: 0;
`;

export const StyledMessageButton = styled(StyledButton)`
  border: 1px solid ${({ isDark }) => (isDark ? COLORS.white[30] : COLORS.brown.light[100])};
  box-sizing: border-box;

  &:disabled {
    background: ${({ isDark, buttonType }) => BUTTON_STYLES.background[buttonType].default[isDark ? 'dark' : 'light']};
    color: ${({ isDark, buttonType }) => BUTTON_STYLES.color[buttonType].default[isDark ? 'dark' : 'light']};
    cursor: default;
  }
`;
