import React, { FC } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { ROUTE_PROFILE_SETTINGS, ROUTE_PROFILE_EDIT, ROUTE_SPOTLIGHTS_EDIT } from 'routes';
import { useGoBack } from 'helpers/routingHelper';
import SpotlightsModal from 'components/Spotlights/Modal/Modal';
import { useProfileSettingsLinks } from './hooks';

import Menu from './Menu';
import EditProfile from './EditProfile';

const ProfileSettings: FC = () => {
  const goBack = useGoBack();

  const { pathname } = useLocation();
  const { editProfile, home } = useProfileSettingsLinks();
  const { replace } = useHistory();

  return (
    <Switch>
      <Route exact path={ROUTE_PROFILE_SETTINGS}>
        <Menu handleClose={goBack} />
      </Route>
      <Route path={ROUTE_PROFILE_EDIT}>
        <EditProfile
          handleClose={goBack}
          handlePrev={() => (pathname === ROUTE_PROFILE_EDIT ? goBack() : replace(editProfile))}
        />
      </Route>
      <Route path={ROUTE_SPOTLIGHTS_EDIT}>
        <SpotlightsModal handleClose={goBack} handlePrev={() => replace(home)} />
      </Route>
    </Switch>
  );
};

export default ProfileSettings;
