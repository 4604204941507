import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import Grain from 'components/UI/Grain';
import Avatar from 'components/UI/Avatar';
import IconCircle from 'components/UI/IconCircle';
import LoaderContent from 'components/UI/LoaderContent';
import { ReactComponent as ListIcon } from 'assets/icons/List.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icons/Bookmark.svg';

import { ROUTE_ADD_STYLE_AND_THOUGHT, ROUTE_ADD_TO_LIST } from 'routes';
import { useSavePickMutation } from 'constants/graphqlTypes';
import useToast from 'helpers/useToast';
import { INiceFindLocationStateVariable } from './models';

import {
  StyledTitleWrap,
  StyledTitle,
  StyledSubTitle,
  StyledModalWrap,
  StyledControls,
  StyledControlsText,
  StyledOptionTitle,
  StyledOptionSubTitle,
  StyledControlOption,
  StyledSaveIcon,
} from './styled';

interface INiceFindProps {
  handleClose: () => void;
}

const NiceFind: FC<INiceFindProps> = ({ handleClose }) => {
  const [isSaved, setIsSaved] = useState(false);
  const { state, pathname } = useLocation<{ variable: INiceFindLocationStateVariable }>();

  const { variable } = state ?? {};

  const { sourceUrl, sourceDomainName, userData, cardImage, cardColorValue } = variable ?? {};

  const [savePick, { loading }] = useSavePickMutation({ onCompleted: () => setIsSaved(true) });
  const { setToast } = useToast();

  const handleSavePick = () =>
    savePick({
      variables: { url: sourceUrl ?? '' },
      onError: ({ message }) => setToast({ isToastOpen: true, toastItemName: message, toastError: true }),
    });

  const { replace } = useHistory();

  const goToNextStep = (targetPathname: string) =>
    replace({
      pathname: targetPathname,
      state: { ...state, variable: { ...state.variable, prevPath: pathname, isFromNiceFind: true } },
    });

  return (
    <StyledModalWrap backgroundImage={cardImage} backgroundColor={cardColorValue}>
      <Modal handleClose={handleClose} fadeIn>
        <Grain />
        {!loading && (
          <div>
            <StyledTitleWrap>
              <StyledTitle>
                {isSaved && (
                  <StyledSaveIcon>
                    <BookmarkIcon width={48} height={48} viewBox="0 0 24 24" />
                  </StyledSaveIcon>
                )}
                {isSaved ? 'Saved for later.' : 'Nice find.'}
              </StyledTitle>
              <StyledSubTitle>{sourceDomainName}</StyledSubTitle>
            </StyledTitleWrap>
            {!isSaved && (
              <StyledControls>
                <StyledControlOption onClick={() => goToNextStep(ROUTE_ADD_STYLE_AND_THOUGHT)}>
                  {userData && <Avatar user={userData} />}
                  <StyledControlsText>
                    <StyledOptionTitle>Add to profile</StyledOptionTitle>
                    <StyledOptionSubTitle>Quick sharing</StyledOptionSubTitle>
                  </StyledControlsText>
                </StyledControlOption>
                <StyledControlOption onClick={() => goToNextStep(ROUTE_ADD_TO_LIST)}>
                  <IconCircle Icon={ListIcon} />
                  <StyledControlsText>
                    <StyledOptionTitle>Add to list</StyledOptionTitle>
                    <StyledOptionSubTitle>Add to profile in an organized way</StyledOptionSubTitle>
                  </StyledControlsText>
                </StyledControlOption>
                <StyledControlOption onClick={handleSavePick}>
                  <IconCircle hasStroke Icon={BookmarkIcon} />
                  <StyledControlsText>
                    <StyledOptionTitle>Save for later </StyledOptionTitle>
                    <StyledOptionSubTitle>Private</StyledOptionSubTitle>
                  </StyledControlsText>
                </StyledControlOption>
              </StyledControls>
            )}
          </div>
        )}
        {loading && <LoaderContent isCentered isFullScreen isDark />}
      </Modal>
    </StyledModalWrap>
  );
};

export default NiceFind;
