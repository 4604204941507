import { styled } from 'linaria/react';
import { StyledListTitle } from 'components/AddContent/AddToList/styled';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';

export const StyledModalInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 86vh;
  }
`;

export const StyledTitle = styled(StyledListTitle)`
  padding: 32px 0 16px;
  font: 400 22px/130% ${FONTS.primary};
`;

export const StyledContainer = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 100px;
  margin: 0 -8px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledCard = styled.div<{ content: number; isShowCheckbox: boolean }>`
  cursor: pointer;
  margin: 0;
  padding: 0 8px 9px;
  width: 100%;

  .card {
    display: block;
    pointer-events: none;

    &::before {
      border-radius: 10px;
      border: 1.5px solid ${COLORS.white[100]};
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      display: ${({ content }) => (content > 0 ? 'block' : 'none')};
      z-index: 100;
    }

    &::after {
      align-items: center;
      background: ${({ content }) => (content > 0 ? COLORS.white[100] : COLORS.white[30])};
      border: 1px solid ${COLORS.white[100]};
      border-radius: 4px;
      bottom: 8px;
      content: ${({ content }) => (content > 0 ? `'${content}'` : '""')};
      display: ${({ content, isShowCheckbox }) => (isShowCheckbox || content > 0 ? 'flex' : 'none')};
      font: 600 14px/140% ${FONTS.secondary};
      color: ${COLORS.brown.dark[100]};
      height: 24px;
      justify-content: center;
      right: 8px;
      position: absolute;
      width: 24px;
    }
  }
`;

export const StyledButtonWrap = styled.div`
  bottom: 24px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 100;

  > button {
    box-shadow: 0px 19px 8px rgba(0, 0, 0, 0.04), 0px 11px 6px rgba(0, 0, 0, 0.13), 0px 5px 5px rgba(0, 0, 0, 0.21),
      0px 1px 3px rgba(0, 0, 0, 0.25), 0px 0px 0px rgba(0, 0, 0, 0.25);
    max-width: 342px;
    width: 100%;

    :disabled {
      background: ${COLORS.white[30]};
      color: ${COLORS.white.off[100]};
    }
  }
`;
