export const ROUTE_LANDING = '/';
export const ROUTE_WELCOME = '/login';
export const ROUTE_DISCOVERY = '/discovery';
export const ROUTE_CHECK_EMAIL = '/check-email';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_USER_PROFILE = '/@:profileName';
export const ROUTE_USER_PROFILE_PREVIEW = `${ROUTE_USER_PROFILE}/preview`;
export const ROUTE_USER_FOLLOWERS = `${ROUTE_USER_PROFILE}/followers`;
export const ROUTE_USER_FOLLOWINGS = `${ROUTE_USER_PROFILE}/followings`;
export const ROUTE_USER_TOPICS = `${ROUTE_USER_PROFILE}/topics`;
export const ROUTE_TOPIC_DETAIL = '/topics/:topicId';
export const ROUTE_TOPIC_ITEM_DETAIL = '/topics/:topicId/:pickId';
export const ROUTE_USER_ALL_PICKS = `${ROUTE_USER_PROFILE}/all-picks`;
export const ROUTE_USER_ALL_PICKS_LAYOUT = `${ROUTE_USER_ALL_PICKS}/layout`;
export const ROUTE_USER_ALL_PICKS_ITEM = `${ROUTE_USER_PROFILE}/all-picks/:pickId`;
export const ROUTE_ACTIVITY = '/activity';
export const ROUTE_ACTIVITY_REACTIONS = `${ROUTE_ACTIVITY}/:thoughtId`;
export const ROUTE_SEARCH = '/search';
export const ROUTE_SEARCH_RESULT = `${ROUTE_SEARCH}/result`;
export const ROUTE_CARD_DETAIL = '/picks/:pickId';
export const ROUTE_FOLLOWING_CARD_DETAIL = '/following/:pickId';
export const ROUTE_EVERYONE_CARD_DETAIL = '/everyone/:pickId';
export const ROUTE_CARD_DETAIL_THOUGHTS = `${ROUTE_CARD_DETAIL}/thoughts`;
export const ROUTE_PRIVACY = '/privacy-policy';
export const ROUTE_TERMS = '/terms-and-conditions';
export const ROUTE_COMMUNITY = '/community-guidelines';
export const ROUTE_UNAUTHORIZED = '/unauthorized';
export const ROUTE_REPORT = '/report';
export const ROUTE_OFF_PLATFORM_SHARING = '/join';
export const ROUTE_INVITE_USER_SHARING = '/invite-friends';
export const ROUTE_UNKNOWN_ERROR = '/unknown-error';

// ROUTES for custom feed creation
export const ROUTE_CREATE_FEED = `/create-feed`;
export const ROUTE_CREATE_FEED_USERS = `${ROUTE_CREATE_FEED}/users`;
export const ROUTE_CREATE_FEED_NAME = `${ROUTE_CREATE_FEED}/name`;

// ROUTES for custom feed editing
export const ROUTE_EDIT_FEED = `/edit-feed`;
export const ROUTE_EDIT_FEED_NAME = `${ROUTE_EDIT_FEED}/edit-feed-name`;
export const ROUTE_FILTER_FEED = `/filter-feed`;
export const ROUTE_EDIT_FEED_USERS = `${ROUTE_EDIT_FEED}/users`;
export const ROUTE_EDIT_FEED_TOPICS = `${ROUTE_EDIT_FEED}/topics`;

// ROUTES for custom feed detailed page
export const ROUTE_CUSTOM_FEED_DETAIL = '/feed/:feedId';
export const ROUTE_FEED_ITEMS = '/feed/:feedId/:pickId';

// ROUTES for Sparks pages
export const ROUTE_SPARKS = '/sparks';
export const ROUTE_USER_ALL_SPARKS = `${ROUTE_USER_PROFILE}/all-sparks`;
export const ROUTE_SPARK_DETAIL = `/sparks/:sparkId`;

// ROUTES from Spark add response flow
export const ROUTE_SPARK_RESP_ADD_PICK = `/spark-response`;
export const ROUTE_SPARK_RESP_ADD_STYLE = `${ROUTE_SPARK_RESP_ADD_PICK}/add-style`;
export const ROUTE_SPARK_RESP_ADD_THOUGHT = `${ROUTE_SPARK_RESP_ADD_PICK}/add-thought`;
// Spark report style steps
export const ROUTE_SPARK_RESP_STYLE_DESCRIPTION = `${ROUTE_SPARK_RESP_ADD_PICK}/style-description`;
export const ROUTE_SPARK_RESP_REPORT_STYLE_ISSUE = `${ROUTE_SPARK_RESP_STYLE_DESCRIPTION}/report-style-issue`;
export const ROUTE_SPARK_RESP_REPORT_STYLE_OTHER = `${ROUTE_SPARK_RESP_REPORT_STYLE_ISSUE}/report-style-other`;

// ROUTES for Onboarding
export const ROUTE_ONBOARDING = '/onboarding';
export const ROUTE_ONBOARDING_WELCOME = `${ROUTE_ONBOARDING}/welcome`;
export const ROUTE_ONBOARDING_FULLNAME = `${ROUTE_ONBOARDING}/fullname`;
export const ROUTE_ONBOARDING_USERNAME = `${ROUTE_ONBOARDING}/username`;
export const ROUTE_ONBOARDING_BIO = `${ROUTE_ONBOARDING}/bio`;
export const ROUTE_ONBOARDING_IMAGE = `${ROUTE_ONBOARDING}/image`;
export const ROUTE_ONBOARDING_TOPICS = `${ROUTE_ONBOARDING}/topics`;
export const ROUTE_ONBOARDING_NOTIFICATIONS = `${ROUTE_ONBOARDING}/notifications`;
export const ROUTE_ONBOARDING_SUCCESS = `${ROUTE_ONBOARDING}/success`;

// ROUTES for ONBOARDING V2
export const ROUTE_ONBOARDING_PREVIEW = `${ROUTE_ONBOARDING}/preview`;
export const ROUTE_ONBOARDING_PROFILE = `${ROUTE_ONBOARDING}/profile`;
export const ROUTE_ONBOARDING_PROFILE_INTRO = `${ROUTE_ONBOARDING_PROFILE}/intro`;
export const ROUTE_ONBOARDING_PROFILE_INTERESTS = `${ROUTE_ONBOARDING_PROFILE}/interests`;
export const ROUTE_ONBOARDING_PROFILE_USERNAME = `${ROUTE_ONBOARDING_PROFILE}/username`;
export const ROUTE_ONBOARDING_COMPLETION = `${ROUTE_ONBOARDING}/complete`;
// Networking step for OnboardingV2
export const ROUTE_ONBOARDING_NETWORKING = `${ROUTE_ONBOARDING}/networking`;
export const ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION = `${ROUTE_ONBOARDING_NETWORKING}/user-suggestion`;
export const ROUTE_ONBOARDING_NETWORKING_CONFIRMATION = `${ROUTE_ONBOARDING_NETWORKING}/confirmation`;
// Content tutorial for OnboardingV2 (steps are presented in the same chronology as they are presented in figma des)
export const ROUTE_ONBOARDING_CONTENT = `${ROUTE_ONBOARDING}/content`;
export const ROUTE_ONBOARDING_CONTENT_INTRO = `${ROUTE_ONBOARDING_CONTENT}/intro`;
export const ROUTE_ONBOARDING_CONTENT_LIST_CREATION = `${ROUTE_ONBOARDING_CONTENT}/create-list`;
export const ROUTE_ONBOARDING_CONTENT_LIST_DETAIL = `${ROUTE_ONBOARDING_CONTENT}/list`;
export const ROUTE_ONBOARDING_CONTENT_LIST_PREVIEW = `${ROUTE_ONBOARDING_CONTENT}/preview`;
export const ROUTE_ONBOARDING_CONTENT_CONFIRMATION_FINISH = `${ROUTE_ONBOARDING_CONTENT}/confirmation-finish`;
// ROUTES for Lists
export const ROUTE_LIST_DETAIL = '/lists/:listId';
export const ROUTE_LIST_ITEM = '/lists/:listId/:pickId';
export const ROUTE_LIST_INVITE = `/invite/:inviteId`;
export const ROUTE_LIST_EDIT = `${ROUTE_LIST_DETAIL}/edit`;
export const ROUTE_LIST_EDIT_NAME = `${ROUTE_LIST_EDIT}/name`;
export const ROUTE_LIST_EDIT_DESCRIPTION = `${ROUTE_LIST_EDIT}/description`;
export const ROUTE_LIST_EDIT_COLLABORATORS = `${ROUTE_LIST_EDIT}/collaborators`;
export const ROUTE_LIST_INVITE_COLLABORATORS = `${ROUTE_LIST_EDIT_COLLABORATORS}/invite`;
export const ROUTE_LIST_EDIT_COLLABORATORS_INVITATION = `${ROUTE_LIST_EDIT_COLLABORATORS}/invitation`;
export const ROUTE_LIST_EDIT_PRIVACY = `${ROUTE_LIST_EDIT}/privacy`;
export const ROUTE_LIST_CHANGE_LAYOUT = `${ROUTE_LIST_EDIT}/layout`;
export const ROUTE_LIST_EDIT_ORDER = `${ROUTE_LIST_EDIT}/order`;

// Routes for Auth
export const ROUTE_AUTH_REDIRECT = '/auth';
export const ROUTE_SIGNIN = '/signin';

// Routes for Adding answer for Question Of The Day
export const ROUTE_ADD_ANSWER = '/add-answer';
export const ROUTE_ANSWER_ADD_LIST = `${ROUTE_ADD_ANSWER}/add-list`;
export const ROUTE_ANSWER_ADD_TO_LIST = `${ROUTE_ADD_ANSWER}/add-to-list`;
export const ROUTE_ANSWER_ADD_STYLE = `${ROUTE_ADD_ANSWER}/add-style`;
export const ROUTE_ANSWER_ADD_THOUGHT = `${ROUTE_ADD_ANSWER}/add-thought`;

// Add content routes
export const ROUTE_ADD_CONTENT = '/add-content';
export const ROUTE_CREATE_LIST = `${ROUTE_ADD_CONTENT}/create-list`;
export const ROUTE_ADD_LIST = `${ROUTE_ADD_CONTENT}/add-list`;
export const ROUTE_NICE_FIND = `${ROUTE_ADD_CONTENT}/nice-find`;
export const ROUTE_ADD_TO_LIST = `${ROUTE_ADD_CONTENT}/add-to-list`;
export const ROUTE_ADD_PICK = `${ROUTE_ADD_CONTENT}/add-pick`;
export const ROUTE_ADD_STYLE = `${ROUTE_ADD_CONTENT}/add-style`;
export const ROUTE_ADD_STYLE_AND_THOUGHT = `${ROUTE_ADD_CONTENT}/add-style-and-thought`;
export const ROUTE_ADD_THOUGHT = `${ROUTE_ADD_CONTENT}/add-thought`;
export const ROUTE_ADD_SPARK = `${ROUTE_ADD_CONTENT}/spark`;
export const ROUTE_ADD_SPARK_STYLE = `${ROUTE_ADD_SPARK}/style`;

// Add saved content routes
export const ROUTE_ADD_SAVED_CONTENT = '/add-saved-content';
export const ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST = `${ROUTE_ADD_SAVED_CONTENT}/choose-list`;
export const ROUTE_ADD_SAVED_CONTENT_CREATE_LIST = `${ROUTE_ADD_SAVED_CONTENT}/create-list`;

// Offering reporting styles routes
export const ROUTE_STYLE_DESCRIPTION = `${ROUTE_ADD_STYLE}/style-description`;
export const ROUTE_REPORT_STYLE_ISSUE = `${ROUTE_STYLE_DESCRIPTION}/report-style-issue`;
export const ROUTE_REPORT_STYLE_OTHER = `${ROUTE_REPORT_STYLE_ISSUE}/report-style-other`;

// Profile setting
export const ROUTE_PROFILE_SETTINGS = '/profile-settings';
export const ROUTE_PROFILE_EDIT = `${ROUTE_PROFILE_SETTINGS}/edit-profile`;
export const ROUTE_SPOTLIGHTS_EDIT = `${ROUTE_PROFILE_SETTINGS}/edit-spotlights`;
export const ROUTE_CHANGE_PHOTO = `${ROUTE_PROFILE_EDIT}/change-photo`;
export const ROUTE_CHANGE_NAME = `${ROUTE_PROFILE_EDIT}/change-name`;
export const ROUTE_CHANGE_BIO = `${ROUTE_PROFILE_EDIT}/change-bio`;

// about us
export const ROUTE_ABOUT = '/about-us';

// settings
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_ACCOUNT_MANAGEMENT = `${ROUTE_SETTINGS}/account-management`;
export const ROUTE_ACCOUNT_MANAGEMENT_TOPICS = `${ROUTE_ACCOUNT_MANAGEMENT}/topics`;
export const ROUTE_ACCOUNT_MANAGEMENT_DETAILS = `${ROUTE_ACCOUNT_MANAGEMENT}/account-details`;
export const ROUTE_ACCESSIBILITY = `${ROUTE_SETTINGS}/accessibility`;
export const ROUTE_NOTIFICATION_SETTING = `${ROUTE_SETTINGS}/notifications`;
export const ROUTE_NOTIFICATION_EMAIL_SETTING = `${ROUTE_NOTIFICATION_SETTING}/email`;
export const ROUTE_NOTIFICATION_PUSH_SETTING = `${ROUTE_NOTIFICATION_SETTING}/push`;
export const ROUTE_NOTIFICATION_PUSH_SETTING_SPECIFIC_USERS = `${ROUTE_NOTIFICATION_PUSH_SETTING}/specific-users`;
export const ROUTE_NOTIFICATION_COLLABORATE_ACTIVITY = `${ROUTE_NOTIFICATION_SETTING}/collaborate`;
export const ROUTE_PRIVACY_AND_DATA = `${ROUTE_SETTINGS}/privacy-and-data`;
export const ROUTE_BLOCKED_ACCOUNTS = `${ROUTE_PRIVACY_AND_DATA}/blocked-accounts`;
export const ROUTE_BLOCKED_ACCOUNTS_SEARCH = `${ROUTE_BLOCKED_ACCOUNTS}/search`;
export const ROUTE_HIDDEN_WORDS = `${ROUTE_PRIVACY_AND_DATA}/hidden-words`;
export const ROUTE_CUSTOM_WORDS = `${ROUTE_PRIVACY_AND_DATA}/custom-words`;
export const ROUTE_DATA_PREFERENCES = `${ROUTE_PRIVACY_AND_DATA}/data-preferences`;
export const ROUTE_REQUEST_YOUR_DATA = `${ROUTE_PRIVACY_AND_DATA}/request-your-data`;
export const ROUTE_MUTED_ACCOUNTS = `${ROUTE_PRIVACY_AND_DATA}/muted-accounts`;
export const ROUTE_MUTED_ACCOUNTS_SEARCH = `${ROUTE_MUTED_ACCOUNTS}/search`;
export const ROUTE_CONNECTING_WITH_OTHERS = `${ROUTE_PRIVACY_AND_DATA}/connecting-with-others`;

// delete account
export const ROUTE_DELETE_MY_ACCOUNT = `${ROUTE_PRIVACY_AND_DATA}/delete-account`;
export const ROUTE_BEFORE_GO = `${ROUTE_DELETE_MY_ACCOUNT}/before-go`;
export const ROUTE_REASSIGN_ADMINS = `${ROUTE_DELETE_MY_ACCOUNT}/reassign-admins`;
export const ROUTE_DELETE_PICKS = `${ROUTE_DELETE_MY_ACCOUNT}/delete-picks`;
export const ROUTE_CONFIRM_DELETION = `${ROUTE_DELETE_MY_ACCOUNT}/confirm-deletion`;

// questions
export const ROUTE_QUESTION_DETAIL = '/question/:questionId';

export const ROUTE_HELP = '/help';
export const ROUTE_PRIVACY_APPROACH = `${ROUTE_HELP}/privacy-approach`;

export const ROUTE_UI = '/ui';

// notion root route
export const ROOT_NOTION_ROUTE = 'https://guidehuman.notion.site/';

// exit form route
export const ROUTE_EXIT_FORM = '/exit-form';

// question of the day
export const ROUTE_QUESTION_OF_THE_DAY = '/question-of-the-day';

// guest-lists
export const ROUTE_GUEST_LIST_BASE = '/guest-lists';
export const ROUTE_GUEST_LIST_DETAIL = `${ROUTE_GUEST_LIST_BASE}/:id`;
export const ROUTE_GUEST_LIST_SERIES = `${ROUTE_GUEST_LIST_BASE}-series/:id`;

// messages
export const ROUTE_MESSAGES = '/messages';
export const ROUTE_MESSAGES_CHAT = `${ROUTE_MESSAGES}/:id`;
export const ROUTE_MESSAGES_NEW = `${ROUTE_MESSAGES}/new`;
export const ROUTE_MESSAGES_SEARCH = `${ROUTE_MESSAGES}/search`;
export const ROUTE_MESSAGES_SETTINGS = `${ROUTE_MESSAGES}/settings`;
export const ROUTE_MESSAGES_SETTINGS_TITLE = `${ROUTE_MESSAGES_SETTINGS}/change-title`;

export const ROUTE_SELECT_CHAT_MODAL = `/select-chat`;
export const ROUTE_MESSAGES_SETTINGS_ADD_MEMBERS = `${ROUTE_MESSAGES_SETTINGS}/add-members`;

export const ROUTE_SAVED_PICKS = `/saved-picks`;
export const ROUTE_ARCHIVE = `/archive`;

// channels
export const ROUTE_CHANNELS_LISTS = '/channels-lists/:type/:id';
export const ROUTE_CUSTOM_FEEDS = '/custom-feeds';
export const ROUTE_FEEDS_BY_TOPIC = '/feeds-by-topic';
export const ROUTE_FEEDS_BY_TYPE = '/feeds-by-type';
