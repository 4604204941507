import React, { FC, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import {
  ROUTE_ADD_CONTENT,
  ROUTE_ADD_LIST,
  ROUTE_ADD_PICK,
  ROUTE_ADD_SPARK,
  ROUTE_ADD_STYLE,
  ROUTE_ADD_STYLE_AND_THOUGHT,
  ROUTE_ADD_THOUGHT,
  ROUTE_ADD_TO_LIST,
  ROUTE_CREATE_LIST,
  ROUTE_LIST_ITEM,
  ROUTE_NICE_FIND,
  ROUTE_STYLE_DESCRIPTION,
} from 'routes';
import { CardStyleSchema } from 'constants/graphqlTypes';
import { transformRoute, useGoBack } from 'helpers/routingHelper';
import { COLORS } from 'styles/constants';

import AddSpark from 'components/Sparks/Routes/AddSpark/AddSpark';
import Menu from './Menu';
import AddPick from './AddPick';
import CreateList from './CreateList';
import AddStyle from './AddStyle';
import AddThought from './AddThought';

import { useAddContentLinks, useExtractData, useNavigateFromCreatedList } from './helpers/hooks';
import { IAddContentLocation, ListRoutingParams } from './helpers/models';
import ReportStyles from './AddStyle/ReportStyles';
import SavedToList from './AddSavedToList';
import NiceFind from './NiceFind';
import AddStyleAndThought from './AddStyleAndThought';

const AddContent: FC = () => {
  const { state } = useLocation<IAddContentLocation>();
  const {
    listCardId: currentListCardId = '',
    listTitle: currentListTitle,
    isPrivateList,
    isMovePick,
    disableListRedirect,
  } = state?.variable ?? {};

  const [listToAdd, setListToAdd] = useState(currentListCardId);
  const [listTitle, setListTitle] = useState(currentListTitle ?? '');
  const [listRoutingParams, setListRoutingParams] = useState<ListRoutingParams>({
    id: currentListCardId,
    isPrivateList: !!isPrivateList,
  });
  const [url, setUrl] = useState('');
  const [style, setStyle] = useState<CardStyleSchema | undefined>();
  const [styleItemId, setStyleItemId] = useState(0);
  const [color, setColor] = useState(COLORS.transparent);

  const { pickId, cardId, title, image, setExtractData } = useExtractData();
  const { pathname } = useLastLocation() ?? {};

  const { replace } = useHistory();
  const fadeIn = !pathname?.includes(ROUTE_ADD_CONTENT);
  const goBack = useGoBack();
  const { addToList } = useAddContentLinks();

  const handleCreateListCompleted = useNavigateFromCreatedList(setListTitle, setListToAdd, setListRoutingParams);

  const goToNewListItem = (newListId: string) => {
    replace(transformRoute(ROUTE_LIST_ITEM, { listId: newListId, pickId }), {
      prevPath: state.prevPath,
    });
  };

  return (
    <Switch>
      <Route exact path={ROUTE_ADD_CONTENT}>
        <Menu fadeIn={fadeIn} handleClose={goBack} />
      </Route>
      <Route path={ROUTE_CREATE_LIST}>
        <CreateList handleClose={goBack} />
      </Route>
      <Route path={ROUTE_ADD_LIST}>
        <CreateList handleClose={handleCreateListCompleted} handlePrev={() => replace(addToList)} />
      </Route>
      <Route path={ROUTE_ADD_PICK}>
        <AddPick
          fadeIn={fadeIn}
          listId={currentListCardId}
          url={url}
          setUrl={setUrl}
          setExtractData={setExtractData}
          handleClose={goBack}
        />
      </Route>
      <Route path={ROUTE_NICE_FIND}>
        <NiceFind handleClose={goBack} />
      </Route>
      <Route path={ROUTE_ADD_TO_LIST}>
        <SavedToList
          fadeIn={fadeIn}
          pickId={pickId}
          setExtractData={setExtractData}
          setListToAdd={setListToAdd}
          setListRoutingParams={setListRoutingParams}
          setListTitle={setListTitle}
          handleClose={goBack}
        />
      </Route>
      <Route exact path={ROUTE_ADD_STYLE}>
        <AddStyle
          itemId={styleItemId}
          setItemId={setStyleItemId}
          preExtractTitle={title}
          preExtractImage={image}
          setStyle={setStyle}
          cardId={cardId}
          pickId={pickId}
          handleClose={goBack}
          listTitle={listTitle}
          currentListId={currentListCardId} // id || cardId
          color={color}
          setColor={setColor}
          removeUrl={() => setUrl('')}
        />
      </Route>
      <Route exact path={ROUTE_ADD_STYLE_AND_THOUGHT}>
        <AddStyleAndThought
          listCardId={listToAdd}
          handleClose={isMovePick && !disableListRedirect ? () => goToNewListItem(listRoutingParams.id) : goBack}
          listTitle={listTitle}
          listRoutingParams={listRoutingParams}
          shouldRedirectToList
        />
      </Route>
      <Route path={ROUTE_STYLE_DESCRIPTION}>
        <ReportStyles cardId={cardId} />
      </Route>
      <Route path={ROUTE_ADD_THOUGHT}>
        <AddThought
          listCardId={listToAdd}
          pickId={pickId}
          cardId={cardId}
          cardStyle={style}
          handleClose={isMovePick && !disableListRedirect ? () => goToNewListItem(listRoutingParams.id) : goBack}
          listTitle={listTitle}
          listRoutingParams={listRoutingParams}
          shouldRedirectToList
        />
      </Route>
      <Route path={ROUTE_ADD_SPARK}>
        <AddSpark />
      </Route>
    </Switch>
  );
};

export default AddContent;
