import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Avatars from 'components/UI/Avatars';
import { ReactComponent as ShareIcon } from 'assets/icons/InviteToGH.svg';
import { CardSchema, MessageType, UserObjectType } from 'constants/graphqlTypes';
import { ROUTE_OFF_PLATFORM_SHARING } from 'routes';
import { ESearchParams } from 'constants/common';

import { StyledAvatarsWrap, StyledCollaboratorsContainer, StyledPrivateLabel, StyledShare } from './styled';
import { StyledDot } from '../BigCard/Card/styled';

const Footer: FC<{ item: CardSchema }> = ({ item }) => {
  const { isPrivateList, id, creators, hasNewCollaboratorsCards, isCollaborative } = item;
  const filteredCreators =
    creators?.items.reduce((acc: UserObjectType[], creator) => (creator?.user ? acc.concat(creator.user) : acc), []) ??
    [];
  const { push } = useHistory();

  const handleShareContent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    push({
      pathname: ROUTE_OFF_PLATFORM_SHARING,
      search: `?${ESearchParams.Type}=${MessageType.List}&${ESearchParams.Id}=${id}`,
    });
  };

  return (
    <>
      {!isPrivateList && (
        <StyledShare aria-label="Share list" onClick={handleShareContent}>
          <ShareIcon width={16} height={16} viewBox="0 0 18 18" />
        </StyledShare>
      )}
      {!!isCollaborative && (
        <StyledCollaboratorsContainer>
          {isPrivateList && <StyledPrivateLabel>Private</StyledPrivateLabel>}
          <StyledAvatarsWrap>
            <Avatars users={filteredCreators} limit={3} hideNumber />
          </StyledAvatarsWrap>
          {!!hasNewCollaboratorsCards && <StyledDot />}
          {creators?.totalCount} collaborators
        </StyledCollaboratorsContainer>
      )}
      {!isCollaborative && isPrivateList && <StyledPrivateLabel isAbsolutelyPositioned>Private</StyledPrivateLabel>}
    </>
  );
};

export default Footer;
