import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as DiagonalArrow } from 'assets/icons/DiagonalArrow.svg';
import { ReactComponent as PaperPlane } from 'assets/icons/PaperPlane.svg';
import { ReactComponent as PlusSmallIcon } from 'assets/icons/PlusSmall.svg';

import { INavigation } from 'providers/helpers/models';
import { NavigationContext } from 'providers/helpers/contexts';
import { useGotoMoveToList } from 'helpers/useContextMenu';
import { ETutorialStatus, MessageType } from 'constants/graphqlTypes';
import { ROUTE_ADD_TO_LIST, ROUTE_NICE_FIND, ROUTE_SELECT_CHAT_MODAL } from 'routes';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';
import { useProfile } from 'graphQL/profile/hooks';
import useIsModalOpen from 'helpers/useIsModalOpen';
import useGuideTour from 'helpers/useGuideTour';
import useOpenModal from 'helpers/useOpenModal';
import withHapticFeedback from 'helpers/withHapticFeedback';
import getAuthUser from 'helpers/getAuthUser';

import { PICKER } from 'components/GuideTour/constants';
import Highlighter from 'components/Highlighter';
import OnboardingAlert from 'components/UI/OnboardingAlert';
import IconCircle from 'components/UI/IconCircle';

import { StyledLink, StyledList } from './styled';

const DetailMenu: FC = () => {
  const { userId } = getAuthUser();
  const { pickId, listId, feedId } = useParams<{ pickId: string; listId: string; feedId: string }>();
  const { card, loading: cardLoading } = useGetDetailCard(pickId, feedId ?? listId);

  const pickIdOfLoggedUser = card?.picks?.items.find(
    ({ userId: pickedUserId }) => pickedUserId === getAuthUser().userId
  )?.id;

  const { externalSources, isSavedByMe, isPickedByMe, cardId, parentCards } = card ?? {};
  const { cardId: listCardId } = parentCards?.items[0] ?? {};
  const { items: sourcesItems } = externalSources || {};
  const { sourceUrl, sourceDomainName } = sourcesItems?.[0] ?? {};
  const { userData } = useProfile();

  const { isGuideTour } = useGuideTour();
  const { isContentDetailCompleted, loading, tutorialStatus } = useUserSettings();

  const { updateUserSettings } = useUpdateUserSettings({
    isContentDetailCompleted: true,
  });

  const isDisplaySheet =
    !isGuideTour &&
    !loading &&
    !isContentDetailCompleted &&
    !cardLoading &&
    tutorialStatus !== ETutorialStatus.NotStarted;

  const isModalOpen = useIsModalOpen();
  const isHidden = isModalOpen && !isGuideTour;

  const { isScrolled } = useContext<INavigation>(NavigationContext);

  const cardImage = card?.cardStyle?.thumbnailImageSet?.images?.[0]?.url ?? undefined;
  const cardColorValue = card?.cardStyle?.bgColor?.colorValue;
  // will be used in the future commits
  const niceFindLocationVariable = {
    pickId,
    cardId,
    listId,
    card,
    cardImage,
    cardColorValue,
    sourceUrl: sourceUrl || undefined,
    sourceDomainName: sourceDomainName || undefined,
    userData,
  };
  const goToNiceFind = useOpenModal(ROUTE_NICE_FIND, false, niceFindLocationVariable);

  const goToPickFlow = useOpenModal(ROUTE_ADD_TO_LIST, false, {
    pickId,
    cardId,
    isPickFlow: true,
    listId,
    isSavedPick: isSavedByMe && !isPickedByMe,
  });

  const goToChatSelect = useOpenModal(ROUTE_SELECT_CHAT_MODAL, false, {
    entityId: pickId,
    messageType: MessageType.Pick,
    collectionId: null,
  });

  const userPickSchemaRef = card?.extraData?.items?.find((data) => data?.user?.id === userId)?.id;

  const { toggle: goToMovePick } = useGotoMoveToList({
    pickId: pickIdOfLoggedUser ?? '',
    cardId: cardId ?? '',
    currentListId: listCardId,
    userPickSchemaRef,
    disableListRedirect: true,
  });

  const handlePick = () => {
    if (isPickedByMe) {
      goToMovePick?.();
    } else if (isSavedByMe) {
      goToPickFlow();
    } else {
      goToNiceFind();
    }
  };

  return (
    <>
      <StyledList isHidden={isHidden}>
        <li>
          <IconCircle
            hasBorder
            Icon={PlusSmallIcon}
            isDark
            handleClick={withHapticFeedback(() => !isScrolled && handlePick())}
          />
        </li>
        <li>
          <StyledLink href={sourceUrl ?? '#'} target="_blank" rel="noopener noreferrer">
            Visit
            <DiagonalArrow />
          </StyledLink>
        </li>
        <li>
          <IconCircle hasBorder Icon={PaperPlane} isDark handleClick={() => !isScrolled && goToChatSelect()} />
        </li>
      </StyledList>
      {isDisplaySheet && (
        <Highlighter
          id={PICKER}
          borderRadius="50%"
          highlighterOffset={12}
          elementOffset={6}
          highlight
          handleClose={updateUserSettings}
        >
          <OnboardingAlert title="Re-share picks from others" submitText="Got it" handleSubmit={updateUserSettings}>
            Seen a pick from someone else that resonates with you? You can add it to your profile along with your own
            thoughts, by tapping the + button.
          </OnboardingAlert>
        </Highlighter>
      )}
    </>
  );
};

export default DetailMenu;
