import { LocationDescriptor } from 'history';
import { useLocation, useParams } from 'react-router-dom';

import { EPageTypes } from 'constants/posthogEvents';
import { EVisibilityStatusOptions, UserObjectType } from 'constants/graphqlTypes';

import { useUserSettings } from 'graphQL/contextualIntro/hooks';
import { useGetSparkDetail } from 'graphQL/sparks/hooks';

import useReferrerId from 'helpers/useReferrerId';
import useRefIdContext from 'helpers/useContextRefID';
import usePageType from 'helpers/usePageType';
import { transformRoute } from 'helpers/routingHelper';

import useDiscoveryTab from 'helpers/useDiscoveryTab';
import { getEveryoneTime, REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import { EExploreTabs } from 'components/Explore/ExploreTabs/helpers/constants';
import { useCheckForCustomFeed } from 'pages/Explore/hooks';

import {
  ROUTE_EVERYONE_CARD_DETAIL,
  ROUTE_FOLLOWING_CARD_DETAIL,
  ROUTE_LIST_ITEM,
  ROUTE_TOPIC_ITEM_DETAIL,
  ROUTE_USER_ALL_PICKS_ITEM,
  ROUTE_FEED_ITEMS,
} from 'routes';

import { getPathName } from './helpers';

export const useGetPageTypeCardDetailOpenFrom = (): EPageTypes => {
  const { isDiscovery, isSearch, isMessages, isProfile } = usePageType();
  const { discoveryTab } = useDiscoveryTab();
  const isCustomFeed = useCheckForCustomFeed();

  if (isDiscovery && discoveryTab === EExploreTabs.Featured) {
    return EPageTypes.Featured;
  }
  if (isDiscovery && isCustomFeed) {
    return EPageTypes.CustomFeed;
  }
  if (isSearch) {
    return EPageTypes.Search;
  }
  if (isMessages) {
    return EPageTypes.Messages;
  }
  if (isProfile) {
    return EPageTypes.Spotlights;
  }

  return EPageTypes.List;
};

export const useItemUrl = (id?: string, isList?: boolean, guestListId?: string): LocationDescriptor => {
  const descriptor: LocationDescriptor = {};
  // TODO: Need to check: could we delete userRefId?
  const userRefId = useReferrerId();
  const { discoveryTab } = useDiscoveryTab();

  const { isDiscovery, isSearch, isTopics, isSparkDetail } = usePageType();
  const pageTypeCardDetailOpenFrom = useGetPageTypeCardDetailOpenFrom();

  const { pathname, search } = useLocation();
  const discoveryRefId = useRefIdContext();
  const hasFallbackRefId =
    (isDiscovery && discoveryTab === EExploreTabs.Featured) || isSearch || isTopics || isSparkDetail;

  return {
    ...descriptor,
    state: {
      userRefId: discoveryRefId || userRefId,
      guestListId,
      prevPath: `${pathname}${search}`,
      ...(pageTypeCardDetailOpenFrom && { pageType: pageTypeCardDetailOpenFrom }),
      ...(hasFallbackRefId && { fallbackRefId: REF_PLACEHOLDER }),
    },
    pathname: getPathName(id, isList),
  };
};

export const useListItemUrl = (pickId: string): LocationDescriptor => {
  const { listId, profileName, id: guestListId, topicId, feedId } = useParams<{
    listId?: string;
    profileName?: string;
    id: string;
    topicId?: string;
    feedId?: string;
  }>();
  const { isAllPicks, isDiscovery, isSparkDetail, isGuestList } = usePageType();
  const { pathname } = useLocation();

  const { discoveryTab } = useDiscoveryTab();
  const itemUrl = useItemUrl(pickId, undefined, isGuestList ? guestListId : undefined);
  const { spark } = useGetSparkDetail();

  const contextRefId = useRefIdContext();
  const isCustomFeed = useCheckForCustomFeed();

  if (isDiscovery && discoveryTab !== EExploreTabs.Featured && !isCustomFeed) {
    const listUpdateTime = getEveryoneTime();
    return {
      pathname: transformRoute(
        discoveryTab === EExploreTabs.Following ? ROUTE_FOLLOWING_CARD_DETAIL : ROUTE_EVERYONE_CARD_DETAIL,
        { pickId }
      ),
      state: {
        prevPath: pathname,
        userRefId: contextRefId,
        listUpdateTime,
        pageType: discoveryTab === EExploreTabs.Following ? EPageTypes.Following : EPageTypes.Everyone,
      },
    };
  }

  if (isCustomFeed) {
    return {
      pathname: getPathName(pickId),
      state: {
        userRefId: contextRefId,
        prevPath: pathname,
        pageType: EPageTypes.CustomFeed,
        feedId: discoveryTab,
      },
    };
  }

  if (listId) {
    return {
      pathname: transformRoute(ROUTE_LIST_ITEM, { listId, pickId }),
      state: {
        userRefId: contextRefId,
        prevPath: pathname,
        pageType: EPageTypes.List,
      },
    };
  }

  if (feedId) {
    return {
      pathname: transformRoute(ROUTE_FEED_ITEMS, { feedId, pickId }),
      state: {
        userRefId: contextRefId,
        prevPath: pathname,
        pageType: EPageTypes.List,
        feedId,
        fallbackRefId: REF_PLACEHOLDER,
      },
    };
  }

  if (isAllPicks && profileName) {
    return {
      pathname: transformRoute(ROUTE_USER_ALL_PICKS_ITEM, { pickId, profileName }),
      state: { prevPath: pathname, userRefId: contextRefId, pageType: EPageTypes.List },
    };
  }

  if (isSparkDetail) {
    return {
      pathname: getPathName(pickId),
      state: {
        prevPath: pathname,
        userRefId: contextRefId,
        pageType: EPageTypes.List,
        variable: {
          sparkId: spark?.id,
          sparkCardId: spark?.cardId,
        },
      },
    };
  }

  if (topicId) {
    return {
      pathname: transformRoute(ROUTE_TOPIC_ITEM_DETAIL, { topicId, pickId }),
      state: {
        userRefId: contextRefId,
        prevPath: pathname,
        pageType: EPageTypes.List,
        fallbackRefId: REF_PLACEHOLDER,
      },
    };
  }

  return itemUrl;
};

export const useIsUserAllowedToReact = (user?: UserObjectType | null, disableCheck?: boolean): boolean => {
  const { isBlockedByMe, isBlockedMe, isFollowingMe, id } = user ?? {};
  const { messagesAndThoughtsVisibility } = useUserSettings(id, disableCheck);

  const isAllowedToReact =
    messagesAndThoughtsVisibility === EVisibilityStatusOptions.Everyone ||
    (messagesAndThoughtsVisibility === EVisibilityStatusOptions.Following && isFollowingMe);

  return (!user || (!isBlockedByMe && !isBlockedMe && !!isAllowedToReact)) && user?.id !== REF_PLACEHOLDER;
};
