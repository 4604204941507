import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

import Grain from 'components/UI/Grain';
import useModal from 'helpers/useModal';
import useModalFade from 'helpers/useModalFade';
import useClickOutside from 'helpers/useClickOutside';
import InteractionBlock from 'components/InteractionBlock';
import Button from 'components/UI/Button';
import { EUiButtonType } from 'components/UI/Button/constants';
import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { ECardRoleTypeOptions } from 'constants/graphqlTypes';
import SelectOptionsList from 'components/List/SelectOptionsList';

import { TEST_MODAL_CLOSE_BTN, TEST_MODAL_DONE_BTN, TEST_MODAL_SECONDARY_BTN } from 'constants/aqa/modal';
import {
  MODAL_DONE_BUTTON_ID,
  MODAL_CLOSE_BUTTON_ID,
  MODAL_CUSTOM_CLOSE_BUTTON_ID,
  MODAL_OVERLAY_ID,
} from './constants';

import {
  StyledCloseButton,
  StyledHeader,
  StyledModal,
  StyledWrap,
  StyledContent,
  StyledCustomCloseButtonWrapper,
  StyledSelectButton,
} from './styled';

interface IModalProps {
  handleClose: () => void;
  handleDone?: () => void;
  handlePrev?: () => void;
  preventExit?: boolean;
  buttonText?: string;
  disabled?: boolean;
  loading?: boolean;
  secondaryText?: string;
  customCloseText?: string;
  secondaryHandler?: () => void;
  bgColor?: string;
  hideGrain?: boolean;
  isDark?: boolean;
  fadeIn?: boolean;
  fadeOut?: boolean;
  isBtnTransparent?: boolean;
  enableInnerScroll?: boolean;
  selectButtonOptions?: { name: string; value: ECardRoleTypeOptions; description: string }[];
  selectedButtonOption?: { name: string; value: ECardRoleTypeOptions; description: string };
  customContentPadding?: string;
  selectButtonOptionHandler?: Dispatch<
    React.SetStateAction<{ name: string; value: ECardRoleTypeOptions; description: string }>
  >;
  dataTestIdPrevButton?: string;
  dataTestIdCloseButton?: string;
  dataTestIdDoneButton?: string;
  isExitConfirmed?: boolean;
  setShowConfirm?: Dispatch<SetStateAction<boolean>>;
  customHeaderComponent?: ReactNode;
}

const Modal: FC<IModalProps> = ({
  handleClose,
  handleDone,
  handlePrev,
  children,
  buttonText,
  disabled,
  loading,
  secondaryText,
  customCloseText,
  secondaryHandler,
  bgColor,
  hideGrain,
  isDark = true,
  isBtnTransparent,
  fadeIn,
  fadeOut,
  enableInnerScroll,
  selectButtonOptions,
  selectedButtonOption,
  customContentPadding,
  selectButtonOptionHandler,
  dataTestIdPrevButton,
  dataTestIdCloseButton,
  dataTestIdDoneButton,
  isExitConfirmed,
  setShowConfirm,
  preventExit,
  customHeaderComponent,
}) => {
  const { isOpen, hasFade, onClose } = useModalFade({ handleClose, fadeIn, fadeOut, preventExit });
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  useClickOutside(ref, () => setIsSelectOpen(false));

  useModal();

  const customCloseButtonDataId = handlePrev ? dataTestIdPrevButton : dataTestIdCloseButton;

  useEffect(() => {
    if (isExitConfirmed && !preventExit) {
      onClose();
    }
  }, [isExitConfirmed, preventExit]);

  return (
    <StyledWrap
      id={MODAL_OVERLAY_ID}
      hasTransition={hasFade}
      isOpen={isOpen}
      onMouseDown={() => (isExitConfirmed === false ? setShowConfirm?.(true) : onClose())}
    >
      <StyledModal
        className="modal"
        hasTransition={hasFade}
        isOpen={isOpen}
        onMouseDown={(e) => e.stopPropagation()}
        bgColor={bgColor}
      >
        {bgColor && !hideGrain && <Grain />}
        <InteractionBlock swipeSize={40} swipeDownCallback={onClose}>
          <StyledHeader className="modal--header" isReverse={!handleDone}>
            {customCloseText ? (
              <StyledCustomCloseButtonWrapper>
                <Button
                  text={customCloseText}
                  handler={handlePrev ?? onClose}
                  type={EUiButtonType.Transparent}
                  isDark
                  dataTestId={customCloseButtonDataId ?? TEST_MODAL_CLOSE_BTN}
                  id={MODAL_CUSTOM_CLOSE_BUTTON_ID}
                />
              </StyledCustomCloseButtonWrapper>
            ) : (
              <StyledCloseButton
                data-testid={customCloseButtonDataId ?? TEST_MODAL_CLOSE_BTN}
                isPrev={!!handlePrev}
                isDark={isDark}
                onClick={handlePrev ?? onClose}
                id={MODAL_CLOSE_BUTTON_ID}
              />
            )}
            {secondaryText && (
              <Button
                dataTestId={TEST_MODAL_SECONDARY_BTN}
                maxWidth="160px"
                type={EUiButtonType.Primary}
                isDark
                handler={secondaryHandler}
                text={secondaryText}
              />
            )}
            {customHeaderComponent}
            {selectButtonOptions && selectedButtonOption && selectButtonOptionHandler && (
              <div ref={ref}>
                <StyledSelectButton
                  isOpen={isSelectOpen}
                  onClick={() => setIsSelectOpen((prev) => !prev)}
                  isDark={isDark}
                  buttonType={EUiButtonType.Primary}
                >
                  {selectedButtonOption.name}
                  <ChevronLeft viewBox="0 0 24 24" height="14" width="14" />
                </StyledSelectButton>
                {isSelectOpen && (
                  <SelectOptionsList
                    selectOptions={selectButtonOptions}
                    selectedOption={selectedButtonOption}
                    selectOptionHandler={(value) => {
                      selectButtonOptionHandler(value);
                      setIsSelectOpen(false);
                    }}
                  />
                )}
              </div>
            )}
            {handleDone && buttonText && (
              <Button
                id={MODAL_DONE_BUTTON_ID}
                dataTestId={dataTestIdDoneButton ?? TEST_MODAL_DONE_BTN}
                type={isBtnTransparent ? EUiButtonType.Primary : EUiButtonType.Emphasized}
                text={buttonText}
                handler={handleDone}
                loading={loading}
                isDisabled={disabled || loading}
                isDark
              />
            )}
          </StyledHeader>
        </InteractionBlock>
        <StyledContent
          className="modal--content"
          customContentPadding={customContentPadding}
          enableInnerScroll={enableInnerScroll}
        >
          {children}
        </StyledContent>
      </StyledModal>
    </StyledWrap>
  );
};

export default Modal;
