import { useLink } from 'helpers/routingHelper';
import {
  ROUTE_PROFILE_EDIT,
  ROUTE_PROFILE_SETTINGS,
  ROUTE_CHANGE_PHOTO,
  ROUTE_CHANGE_NAME,
  ROUTE_CHANGE_BIO,
  ROUTE_SPOTLIGHTS_EDIT,
} from 'routes';

export const useProfileSettingsLinks = () => ({
  home: useLink(ROUTE_PROFILE_SETTINGS),
  editSpotlights: useLink(ROUTE_SPOTLIGHTS_EDIT),
  editProfile: useLink(ROUTE_PROFILE_EDIT),
  changePhoto: useLink(ROUTE_CHANGE_PHOTO),
  changeName: useLink(ROUTE_CHANGE_NAME),
  changeBio: useLink(ROUTE_CHANGE_BIO),
});
