import { styled } from 'linaria/lib/react';

import { FONTS, BREAKPOINTS, COLORS } from '../../../styles/constants';
import { DEFAULT_GRADIENT } from '../../Collection/helpers';
import { StyledLoader } from '../../../pages/CheckEmailPage/styled';

export const StyledModalWrap = styled.div<{ backgroundImage?: string; backgroundColor?: string }>`
  .modal {
    background: ${({ backgroundImage, backgroundColor }) => {
      if (backgroundImage) {
        return `url(${backgroundImage})`;
      }
      return backgroundColor ?? DEFAULT_GRADIENT;
    }};
    background-size: cover;
    background-position: top center;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      backdrop-filter: blur(12px);
    }

    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      height: 800px;
    }
  }

  .modal--header {
    > button {
      margin-right: 0;
      position: relative;
      z-index: 10;
    }
    flex-direction: row;
  }

  ${StyledLoader} {
    z-index: 10;
  }
`;

export const StyledTitleWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  top: 40%;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
`;

export const StyledTitle = styled.span`
  position: relative;
  font: 400 40px/48px ${FONTS.primary};
  margin: 0;
  white-space: nowrap;
`;

export const StyledSaveIcon = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: calc(100% + 16px);
  left: 50%;
  transform: translateX(-50%);

  path {
    fill: ${COLORS.white[100]};
    stroke: ${COLORS.white[100]};
  }
`;

export const StyledSubTitle = styled.span`
  font: 400 12px/140% ${FONTS.secondary};
  padding-top: 16px;
`;

export const StyledControls = styled.div`
  background: linear-gradient(270deg, rgba(13, 127, 175, 0.25) 0%, rgba(190, 123, 74, 0.25) 100%),
    rgba(255, 255, 255, 0.95);
  border-radius: 22px;
  bottom: 46px;
  display: flex;
  flex-direction: column;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 342px;
  z-index: 10;
`;

export const StyledControlOption = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  padding: 16px;
  cursor: pointer;

  &:nth-child(2) {
    border-top: 1px solid ${COLORS.white[30]};
    border-bottom: 1px solid ${COLORS.white[30]};
  }

  .icon {
    border: 1px solid ${COLORS.brown.light[60]};
  }

  path {
    stroke-width: 1px;
  }
`;

export const StyledControlsText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledOptionTitle = styled.div`
  color: ${COLORS.brown.dark[100]};
  font: 600 18px/130% ${FONTS.secondary};
`;

export const StyledOptionSubTitle = styled.div`
  color: ${COLORS.brown.dark[100]};
  font: 400 14px/140% ${FONTS.secondary};
`;
