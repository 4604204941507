import { useGetUserSettingsQuery, UserSettingsSchema, useUpdateUserSettingsMutation } from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';
import usePageType from 'helpers/usePageType';
import { IUseUpdateUserSettings } from './models';

export const useUserSettings = (id?: string, disable?: boolean): UserSettingsSchema & { loading: boolean } => {
  const { userId } = getAuthUser();
  const { isOnboarding } = usePageType();
  const { data, loading } = useGetUserSettingsQuery({
    skip: isOnboarding || !userId || disable,
    variables: { id: id ?? userId },
  });

  return { ...(data?.allUsers?.items?.[0]?.userSettings ?? {}), loading };
};

export const useUpdateUserSettings = (settings: UserSettingsSchema): IUseUpdateUserSettings => {
  const [updateUserSettings, { loading }] = useUpdateUserSettingsMutation({ variables: settings });

  return { updateUserSettings, loading };
};
