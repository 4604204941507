import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TypeAnimation } from 'react-type-animation';

import { ReactComponent as VerificationIcon } from 'assets/icons/Verification.svg';
import { ChatType, EVisibilityStatusOptions, UserObjectType } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';

import Avatar from 'components/UI/Avatar';
import getAuthUser from 'helpers/getAuthUser';
import useDocumentTitle from 'helpers/useDocumentTitle';
import FollowButton from 'components/FollowButton';
import AvatarPlaceholder from 'components/UI/Avatar/AvatarPlaceholder';
import {
  TEST_ID_PROFILE_VIEW_NAME_LABEL,
  TEST_ID_PROFILE_VIEW_USERNAME_LABEL,
  TEST_PROFILE_ACTION_BTN,
} from 'constants/aqa/profile';

import { EUiButtonType } from 'components/UI/Button/constants';
import { useUserSettings } from 'graphQL/contextualIntro/hooks';
import { useCreateNewChat } from 'graphQL/messages/hooks';
import { ROUTE_MESSAGES_CHAT } from 'routes';
import { transformRoute } from 'helpers/routingHelper';
import { AVATAR_DIMENSION } from './constants';
import { generateFullName } from './helpers';
import ConnectionsButton from './ConnectionsButton';

import {
  StyledFollowButtonContainer,
  StyledFullName,
  StyledInfoContainer,
  StyledMessageButton,
  StyledText,
  StyledUserInfo,
  StyledVerifiedIcon,
} from './styled';

interface IInfoProps {
  noContent: boolean;
  userData?: UserObjectType;
  fontColor: string;
  hideGradient: boolean;
}

const Info: FC<IInfoProps> = ({ noContent, userData, fontColor, hideGradient }) => {
  const { username, userInfo, id: userId, isVerified, isBlockedByMe, isBlockedMe, isFollowingMe } = userData ?? {};
  const { firstName, lastName, bio } = userInfo ?? {};
  const { userId: currentUserId } = getAuthUser();
  const { push } = useHistory();

  useDocumentTitle(generateFullName(userData));
  const { messagesAndThoughtsVisibility } = useUserSettings(userId);
  const isAllowedToMessage =
    messagesAndThoughtsVisibility === EVisibilityStatusOptions.Everyone ||
    (messagesAndThoughtsVisibility === EVisibilityStatusOptions.Following && isFollowingMe);

  const { createChat } = useCreateNewChat((chatId: string) =>
    push(transformRoute(ROUTE_MESSAGES_CHAT, { id: chatId }))
  );

  const [isCreateThreadDisabled, setIsCreateThreadDisabled] = useState(false);

  const handleCreateThread = () => {
    if (userId) {
      createChat([userId], ChatType.Single);
      setIsCreateThreadDisabled(true);
    }
  };

  return (
    <StyledInfoContainer hasWhiteAvatar={!noContent && !hideGradient} hasPaddingBottom={noContent}>
      {userData ? (
        <Avatar user={userData} dimension={AVATAR_DIMENSION} />
      ) : (
        <AvatarPlaceholder dimension={AVATAR_DIMENSION} />
      )}
      <StyledFullName key={`${firstName}${lastName}`} data-testid={TEST_ID_PROFILE_VIEW_USERNAME_LABEL}>
        {userData ? (
          <TypeAnimation wrapper="span" cursor={false} sequence={[`${firstName} ${lastName}`, 100]} speed={35} />
        ) : (
          'Sorry, this profile cannot be found'
        )}
      </StyledFullName>
      <StyledUserInfo>
        {userData && (
          <StyledText data-testid={TEST_ID_PROFILE_VIEW_NAME_LABEL}>
            :{username}
            <StyledVerifiedIcon>
              <VerificationIcon />
            </StyledVerifiedIcon>
          </StyledText>
        )}
        {bio && <StyledText shouldBreak>{bio}</StyledText>}
        {username && (
          <ConnectionsButton username={username} connectionsCount={userData?.userCounters?.connectionsCount ?? 0} />
        )}
      </StyledUserInfo>
      {userId !== currentUserId && username && userData && (
        <StyledFollowButtonContainer>
          <FollowButton dataTestId={TEST_PROFILE_ACTION_BTN} user={userData} isDark={fontColor === COLORS.white[100]} />
          {!isBlockedMe && !isBlockedByMe && isAllowedToMessage && (
            <StyledMessageButton
              onClick={handleCreateThread}
              disabled={isCreateThreadDisabled}
              buttonType={EUiButtonType.Transparent}
              isDark={fontColor === COLORS.white[100]}
            >
              Message
            </StyledMessageButton>
          )}
        </StyledFollowButtonContainer>
      )}
    </StyledInfoContainer>
  );
};

export default Info;
