import React, { FC } from 'react';
import { Waypoint } from 'react-waypoint';

import BigCard from 'components/List/BigCard';
import SmallCard from 'components/List/SmallCardV2';
import LoaderContent from 'components/UI/LoaderContent';

import { getSearchCardTestId } from 'constants/aqa/search';
import { CardSchema } from 'constants/graphqlTypes';
import { StyledGrid, StyledGridItem } from './styled';

interface ProfileGridProps {
  items: CardSchema[];
  loadMore: () => void;
  loading: boolean;
  isDark: boolean;
  pageTestId?: string;
}

const ProfileGrid: FC<ProfileGridProps> = ({ items, loadMore, loading, isDark, pageTestId }) => (
  <StyledGrid>
    {items.map((item, index) => (
      <StyledGridItem key={item.id}>
        {index % 5 ? (
          <SmallCard dataTestId={getSearchCardTestId(index, pageTestId)} item={item} />
        ) : (
          <BigCard dataTestId={getSearchCardTestId(index, pageTestId)} item={item} />
        )}
      </StyledGridItem>
    ))}
    {loading ? <LoaderContent isFullScreen={!items.length} isDark={isDark} /> : <Waypoint onEnter={loadMore} />}
  </StyledGrid>
);

export default ProfileGrid;
