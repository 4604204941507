import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS } from 'styles/constants';
import { StyledPreview as ImportedStyledPreview } from '../AddStyle/styled';

export const StyledModalWrap = styled.div`
  .modal {
    @media screen and (min-width: ${BREAKPOINTS.s}px) {
      min-height: 650px;
    }
  }
`;

export const StyledPreview = styled(ImportedStyledPreview)`
  padding: 8px 0 calc(272px + var(--keyboard-offset));
`;

export const StyledFooter = styled.div`
  align-items: center;
  background-color: ${COLORS.brown.dark[100]};
  border-radius: 20px 20px 0 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  padding: 0 24px calc(24px + var(--keyboard-offset));
  position: absolute;
  transition: 0.2s padding ease-in-out;
  width: 100%;
`;
export const StyledWrap = styled.div`
  padding-top: 24px;
`;

export const StyledComment = styled.div`
  border-top: 1px solid ${COLORS.white[30]};
  display: flex;
  padding: 16px 0;
  width: 100%;
`;

export const StyledStylesSectionWrap = styled.div<{ isHidden?: boolean }>`
  height: ${({ isHidden }) => (isHidden ? '0' : '169px')};
  overflow: hidden;
  transition: all 0.2s ease-in;
  width: 100%;

  > ul {
    padding-bottom: 36px;
  }
`;

export const StyledModalHeaderImage = styled.img`
  border-radius: 2px;
  flex-shrink: 0;
  height: 32px;
  margin-right: auto;
  object-fit: cover;
  width: 32px;
`;
