import React, { FC, useEffect, useMemo, useState } from 'react';

import { CardSchema, EDiscoverItemLayoutOptions, HighlightSchema } from 'constants/graphqlTypes';
import { TEST_CARD_COMMON, TEST_ID_CARD_VIEW_START } from 'constants/aqa/card';
import { EFeaturedFlags } from 'constants/posthogEvents';

import useOpenHiddenCard from 'helpers/useOpenHiddenCards';
import InteractionBlock from 'components/InteractionBlock';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import ReactionsModal from 'components/Reactions/ReactionsModal/ReactionsModal';
import HiddenWordsAlert from 'components/HiddenWordsAlert';
import usePageType from 'helpers/usePageType';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';
import { useCurrentList } from 'graphQL/list/hooks';
import getAuthUser from 'helpers/getAuthUser';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import { StyledWrap, StyledTitle } from 'components/Collection/styled';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';
import useReferrerId from 'helpers/useReferrerId';
import { useIsUserAllowedToReact } from './helpers/hooks';
import Common from './Common';
import FirstUp from './FirstUp';
import FullBleed from './FullBleed';
import Immersive from './Immersive';
import List from './List';

interface ICardProps {
  card: CardSchema;
  layout?: EDiscoverItemLayoutOptions | null;
  isDark?: boolean;
  showActivity?: boolean | null;
  highlight?: HighlightSchema | null;
  disableLinks?: boolean;
  dataTestId?: string;
  shouldDisableLongTap?: boolean;
}

const Card: FC<ICardProps> = ({
  card,
  layout,
  isDark = true,
  showActivity,
  highlight,
  disableLinks,
  dataTestId,
  shouldDisableLongTap,
}) => {
  const [isDisableArea, setDisableArea] = useState(false);

  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const {
    isDiscovery,
    isFollowingTab,
    isEveryoneTab,
    isSearch,
    isAddThought,
    isPickAddStyle,
    isAllPicks,
    isAddSparkResponseStyle,
    isListDetail,
    isSparkDetail,
    isCardDetail,
  } = usePageType();
  const {
    setThoughtId,
    thoughtId,
    setPickerId,
    pickerId,
    cardId,
    setCardId,
    user,
    setUser,
  } = useReactionContextState();
  const { openCard, openIds } = useOpenHiddenCard();
  const { userId } = getAuthUser();
  const isInteractSparks = useFeaturedFlagEnabled(EFeaturedFlags.InteractSpark);

  const { isCollaborative } = useCurrentList() ?? {};
  const isCurrentUserPick = useMemo(() => pickerId === userId, [pickerId]);

  const isCardAllowedToReact = useIsUserAllowedToReact(card?.extraData?.items?.[0]?.user, shouldDisableLongTap);
  const isFallbackAllowedToReact = useIsUserAllowedToReact(user, shouldDisableLongTap);
  const isCardBlocked = (!thoughtId || isAllPicks) && !isCardAllowedToReact;
  const isFallbackBlocked = !!thoughtId && !isFallbackAllowedToReact;

  const disableLongTap =
    isCurrentUserPick || isDisableArea || isFallbackBlocked || isCardBlocked || shouldDisableLongTap;

  const isDisableReaction =
    (isDiscovery && !layout) ||
    (isCollaborative && !layout) ||
    isCurrentUserPick ||
    (isSearch && !layout) ||
    isAddThought ||
    (isSparkDetail && !isInteractSparks);

  const isDisableHiddenAlert = isAddThought || isPickAddStyle || isAddSparkResponseStyle;
  const isCardOpen = openIds?.includes(card.cardId);
  const firstActionInCard = card?.extraData?.items?.[0];

  const isCardReaction =
    firstActionInCard?.__typename !== 'CardCommentSchema' ||
    (firstActionInCard?.__typename === 'CardCommentSchema' && firstActionInCard?.isSuspicious);
  const isShowTitle =
    card?.isHidden && isCardOpen && (!!layout || (!layout && isAllPicks) || (!layout && isListDetail));

  const cardDataTestId = layout ? `${TEST_ID_CARD_VIEW_START}_${layout?.toLowerCase()}` : TEST_CARD_COMMON;

  const referrerId = useReferrerId();
  const actionByUserWithCurrentRefId = card.extraData?.items?.find((action) => action?.user?.id === referrerId);

  useEffect(() => {
    if (!isCardReaction) {
      setThoughtId(card?.extraData?.items?.[0]?.id ?? null);
      setUser(card?.extraData?.items?.[0]?.user ?? null);
      setPickerId(card?.pickedByUsers?.items?.[0]?.id ?? referrerId ?? null);
    }

    if (!isCardDetail && referrerId && actionByUserWithCurrentRefId?.__typename === 'CollectionCardLinkSchema') {
      setPickerId(referrerId);
      setCardId(actionByUserWithCurrentRefId.cardPickId ?? null);
    }

    if (firstActionInCard?.__typename !== 'CollectionCardLinkSchema') {
      setCardId(card.id);
    }

    if (isCardDetail && (card?.extraData?.items?.length || 0) < 2) {
      setPickerId(card?.pickedByUsers?.items?.[0]?.id ?? referrerId ?? null);
    }
  }, []);

  useEffect(() => {
    if (isDisableArea) {
      setIsQuickReactionOpen(false);
    }
  }, [isDisableArea, isQuickReactionOpen]);

  return !card.isHidden || isCardOpen || isDisableHiddenAlert ? (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
        user,
        setUser,
      }}
    >
      <StyledWrap
        data-testid={dataTestId ?? cardDataTestId}
        ref={infoRef}
        disableLinks={isQuickReactionOpen || disableLinks}
        isDark={isDark}
        isNewInFeed={!!card?.isNewInChannels}
      >
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !disableLongTap && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <div>
            {isShowTitle && (
              <StyledTitle isDark={isDark} removePaddingTop={!layout}>
                Hidden {isDiscovery || isFollowingTab || isEveryoneTab ? 'content' : 'pick'}
              </StyledTitle>
            )}
            {!layout && (
              <Common
                dataTestId={dataTestId}
                card={card}
                isDark={isDark}
                showActivity={showActivity}
                disableReactionCallback={(value) => setDisableArea(value)}
              />
            )}
            {layout === EDiscoverItemLayoutOptions.FirstUp && (
              <FirstUp dataTestId={dataTestId} card={card} isDark={isDark} highlight={highlight} />
            )}
            {layout === EDiscoverItemLayoutOptions.FullBleed && (
              <FullBleed dataTestId={dataTestId} card={card} highlight={highlight} />
            )}
            {layout === EDiscoverItemLayoutOptions.Immersive && (
              <Immersive dataTestId={dataTestId} card={card} highlight={highlight} />
            )}
            {layout === EDiscoverItemLayoutOptions.List && <List dataTestId={dataTestId} card={card} isDark={isDark} />}
          </div>
        </InteractionBlock>
        {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && !isDisableReaction && (
          <ReactionMenu
            isShown={isQuickReactionOpen}
            reactionCoordinates={reactionCoordinates}
            handleClickOutside={() => setIsQuickReactionOpen(false)}
            minusTop={minusTop}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsQuickReactionOpen={setIsQuickReactionOpen}
          />
        )}
        {isReactionsModalOpen && <ReactionsModal id={card?.id} handleClose={() => setIsReactionsModalOpen(false)} />}
      </StyledWrap>
    </ReactionsProvider>
  ) : (
    <HiddenWordsAlert
      itemTestId={dataTestId ?? cardDataTestId}
      showHiddenContent={() => openCard?.(card.cardId)}
      isDark={isDark}
      isSmall={!layout}
      hasBottomMargin
    />
  );
};

export default Card;
