import React, { FC, useRef } from 'react';

import { CardSchema, ImageSetSchema } from 'constants/graphqlTypes';
import useTheme from 'helpers/useTheme';
import Description from './Description';
import Footer from './Footer';

import {
  StyledContentCard,
  StyledHorizontalImage,
  StyledImage,
  StyledImageContainer,
  StyledSource,
  StyledSourceWrapper,
  StyledVerticalImage,
} from './styled';

import { ICardStyleModel } from './helpers/models';
import { generateCardStyles } from './helpers/helpers';

interface IContentCardProps {
  item: CardSchema;
  images: ImageSetSchema[];
  card?: ICardStyleModel;
  bgColor?: string;
}

const ContentCard: FC<IContentCardProps> = ({ item, images, card, bgColor }) => {
  const shownCard = card || generateCardStyles(item, images);
  const { imageUrl, gradientStart, gradientEnd, sourceDomainName, styleId, aspectRatio } = shownCard;
  const isDark = useTheme();
  const imageRef = useRef<HTMLImageElement>(null);

  const getImageStyle = () => (aspectRatio && aspectRatio < 1 ? StyledVerticalImage : StyledHorizontalImage);

  return (
    <StyledContentCard isDark={isDark} gradientStart={gradientStart} gradientEnd={gradientEnd} bgColor={bgColor}>
      <Description item={item} />
      <StyledImageContainer className="big-list-image" key={styleId}>
        {imageUrl && (
          <>
            <StyledImage src={imageUrl} ref={imageRef} className={getImageStyle()} />
            <StyledSourceWrapper>
              <StyledSource>{sourceDomainName}</StyledSource>
            </StyledSourceWrapper>
          </>
        )}
      </StyledImageContainer>
      <Footer item={item} />
    </StyledContentCard>
  );
};

export default ContentCard;
