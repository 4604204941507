import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Truncate from 'react-truncate-markup';

import Avatars from 'components/UI/Avatars';

import { CardSchema } from 'constants/graphqlTypes';

import { StyledAvatarsContainer, StyledDescription, StyledInfo, StyledTitle } from './styled';

const Description: FC<{ item: CardSchema }> = ({ item }) => {
  const { title, description, cardsFromCollection, creators, user } = item;

  const { profileName } = useParams<{ profileName: string }>();

  const creator = profileName
    ? creators?.items.find((userItem) => userItem.user?.username === profileName)?.user
    : user;

  return (
    <StyledInfo>
      <Truncate lines={2}>
        <StyledTitle isDark total={cardsFromCollection?.totalCount}>
          {title}
        </StyledTitle>
      </Truncate>
      <Truncate lines={2}>
        <StyledDescription isDark>{description}</StyledDescription>
      </Truncate>
      {!!creator && (
        <StyledAvatarsContainer>
          <Avatars users={[creator]} limit={1} />
        </StyledAvatarsContainer>
      )}
    </StyledInfo>
  );
};

export default Description;
