import React, { FC } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import { getListStyles } from 'components/Collection/helpers';
import BigCardContent from 'components/List/BigCardV2';
import usePageType from 'helpers/usePageType';
import TextCardV2 from 'components/List/BigCardV2/TextCardV2';
import EmptyCard from './EmptyCard';
import ContentCard from './ContentCard';
import TextCard from './TextCard';
import AnimatedContentCard from './AnimatedContentCard';

interface ICardProps {
  item: CardSchema;
}

const BIG_LIST_MIN_MUMBER = 3;

const Card: FC<ICardProps> = ({ item }) => {
  const { images, titles, bgColor, hasItems } = getListStyles(item);
  const { isProfile } = usePageType();
  const isSmallList = !!images.length && images.length < BIG_LIST_MIN_MUMBER;
  const isBigList = !!images.length && images.length >= BIG_LIST_MIN_MUMBER;
  return isProfile && (images.length || titles.length) ? (
    <>
      {!!images.length && <BigCardContent item={item} images={images} bgColor={bgColor} />}
      {!!titles.length && <TextCardV2 item={item} titles={titles} bgColor={bgColor} />}
    </>
  ) : (
    <>
      {isSmallList && !isProfile && <ContentCard item={item} images={images} bgColor={bgColor} />}
      {isBigList && !isProfile && <AnimatedContentCard item={item} images={images} />}
      {!hasItems && <EmptyCard item={item} />}
      {!!titles.length && <TextCard item={item} titles={titles} bgColor={bgColor} />}
    </>
  );
};

export default Card;
