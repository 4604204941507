import React, { FC, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { POSTHOG_EVENTS } from 'constants/posthogEvents';
import {
  TEST_ADD_TO_LIST_CREATE_LIST_BTN,
  TEST_ADD_TO_LIST_TITLE,
  TEST_ID_ADD_TO_LIST_START,
} from 'constants/aqa/addPick';
import { ALL_PICKS } from 'constants/common';
import { CardSchema } from 'constants/graphqlTypes';
import { generateTopicTitle } from 'constants/aqa/signup';
import {
  ROUTE_ADD_SAVED_CONTENT,
  ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST,
  ROUTE_ADD_SAVED_CONTENT_CREATE_LIST,
  ROUTE_ADD_STYLE_AND_THOUGHT,
  ROUTE_ADD_TO_LIST,
} from 'routes';
import { useLink } from 'helpers/routingHelper';
import { usePostHogCapture } from 'helpers/posthogHooks';
import { getListStyles } from 'components/Collection/helpers';
import { useGetLists } from 'graphQL/addContent/addToList/hooks';

import Modal from 'components/Modal';
import LoaderContent from 'components/UI/LoaderContent';
import Button from 'components/UI/MenuButton';
import ListItem from 'components/UI/ListItem';
import CardsMiniaturePile from 'components/CardsMiniaturePile';
import useGuideTour from 'helpers/useGuideTour';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { ReactComponent as ListIcon } from 'assets/icons/List.svg';
import { useGetCoverPickPreview, useAddContentLinks } from '../helpers/hooks';
import { IAddContentLocation, ISetExtractData, ListRoutingParams } from '../helpers/models';

import {
  StyledWrap,
  StyledModalHeader,
  StyledList,
  StyledListWrap,
  StyledMenuButtonContainer,
  StyledHeading,
} from './styled';
import { StyledModalHeaderImage } from '../AddStyleAndThought/styled';

interface IAddSavedToListProps {
  pickId: string;
  cardsPileData?: CardSchema[];
  setListToAdd: Dispatch<SetStateAction<string>>;
  setListTitle: Dispatch<SetStateAction<string>>;
  setListRoutingParams: Dispatch<SetStateAction<ListRoutingParams>>;
  handleClose: () => void;
  handlePrev?: () => void;
  setExtractData?: ({ extractPickId, extractCardId, extractTitle, extractImage }: ISetExtractData) => void;
  fadeIn: boolean;
}

const AddSavedToList: FC<IAddSavedToListProps> = ({
  pickId,
  handleClose,
  handlePrev,
  setListToAdd,
  setListRoutingParams,
  setListTitle,
  setExtractData,
  fadeIn,
  cardsPileData,
}) => {
  const { replace } = useHistory();
  const posthogCapture = usePostHogCapture();
  const { state, pathname } = useLocation<IAddContentLocation | undefined>();

  const { addStyle, addList, addThought, home, addPick, niceFind } = useAddContentLinks();

  const goToAddStyleAndThought = () => {
    replace({
      pathname: ROUTE_ADD_STYLE_AND_THOUGHT,
      state: { ...state, variable: { ...state?.variable, prevPath: ROUTE_ADD_TO_LIST } },
    });
  };

  const addSavedContentLink = useLink(ROUTE_ADD_SAVED_CONTENT);
  const addSavedContentCreateListLink = useLink(ROUTE_ADD_SAVED_CONTENT_CREATE_LIST);

  const isSavedContentChooseList = pathname === ROUTE_ADD_SAVED_CONTENT_CHOOSE_LIST;
  const { variable: stateData } = state ?? {};
  const {
    pickId: statePickId,
    cardId: stateCardId,
    isPickFlow,
    isDetail,
    isMovePick,
    isSavedPick,
    isFromNiceFind,
    cardImage,
  } = stateData ?? {};
  const itemId = statePickId ?? pickId;
  const { items, loading, loadMore } = useGetLists(itemId);
  const { isGuideTour } = useGuideTour();

  const handleNext = () => {
    if (isFromNiceFind) {
      goToAddStyleAndThought();
    } else if (isSavedContentChooseList) {
      replace(addSavedContentLink);
    } else if (isSavedPick) {
      replace(addStyle);
    } else {
      replace(isPickFlow || isDetail || isMovePick ? addThought : addStyle);
    }
  };
  const lists = isGuideTour ? [] : items;
  const hasItems = !!lists.length;

  useEffect(() => {
    if (!itemId) {
      replace(home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    if (stateCardId && statePickId) {
      setExtractData?.({ extractPickId: statePickId, extractCardId: stateCardId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCardId, statePickId]);

  const handleItemClick = (title: string, listCardId?: string, id = '', isPrivateList = false) => {
    setListTitle(title);
    setListToAdd(listCardId ?? '');
    setListRoutingParams({ id, isPrivateList });
    handleNext();
  };

  const handleCreateListClick = () => {
    if (isSavedContentChooseList) {
      replace(addSavedContentCreateListLink);
    } else if (isSavedPick) {
      replace({ ...addList, state: { ...addList.state, isSavedPick } });
    } else {
      replace({ ...addList, state: { ...addList.state, isPickFlow } });
    }
  };

  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  const { item, loading: previewLoading } = useGetCoverPickPreview(pickId);
  const { isSavedByMe } = item ?? {};

  const ModalHeaderImg = isFromNiceFind && cardImage && <StyledModalHeaderImage src={cardImage} />;

  const goToPreviousStep = () => {
    if (isFromNiceFind) {
      replace(niceFind);
    } else if (handlePrev) {
      handlePrev();
    } else if (!isDetail && !isSavedByMe) {
      replace(addPick);
    }
  };

  return (
    <StyledWrap isNiceFind={!!isFromNiceFind && !!ModalHeaderImg}>
      <Modal
        customHeaderComponent={ModalHeaderImg}
        fadeIn={!!isPickFlow && fadeIn && !isGuideTour}
        handleClose={handleClose}
        handlePrev={goToPreviousStep}
        buttonText={isFromNiceFind ? undefined : 'Continue without list'}
        handleDone={
          isFromNiceFind
            ? () => {
                handleItemClick(ALL_PICKS);
                posthogCapture(POSTHOG_EVENTS.AddPickOrgLt);
              }
            : undefined
        }
        loading={loading}
        isExitConfirmed={isExitConfirmed}
        isBtnTransparent
        setShowConfirm={setShowExitWarning}
      >
        {item && !previewLoading && !isFromNiceFind && (
          <StyledModalHeader>
            <CardsMiniaturePile cards={cardsPileData || [item]} />
            <span>{cardsPileData && cardsPileData.length > 1 ? `${cardsPileData.length} picks` : '1 pick'}</span>
          </StyledModalHeader>
        )}
        <StyledHeading data-testid={TEST_ADD_TO_LIST_TITLE}>
          {hasItems ? `Choose a list to share to` : 'Create lists to organize your picks'}
        </StyledHeading>
        <StyledListWrap>
          <StyledList>
            {lists.map((listItem) => {
              const { id: listId, cardId: listCardId, title, cardsFromCollection, isPrivateList } = listItem;
              const { urls, bgColor } = getListStyles(listItem);

              return (
                <ListItem
                  dataTestId={`${TEST_ID_ADD_TO_LIST_START}_item-${generateTopicTitle(title || '')}`}
                  key={listId}
                  title={title ?? ''}
                  isDark
                  isEnable={!!cardsFromCollection?.totalCount}
                  image={urls[0]}
                  bgColor={bgColor}
                  handleClick={() => {
                    handleItemClick(title ?? '', listCardId, listId, !!isPrivateList);
                    posthogCapture(isPrivateList ? POSTHOG_EVENTS.AddPickPrivate : POSTHOG_EVENTS.AddPickPublic);
                  }}
                  hasPrivateLabel={!!isPrivateList}
                />
              );
            })}
            {loading ? <LoaderContent isFullScreen={!items.length} /> : <Waypoint onEnter={loadMore} />}
          </StyledList>
        </StyledListWrap>
        <StyledMenuButtonContainer>
          <Button
            dataTestId={TEST_ADD_TO_LIST_CREATE_LIST_BTN}
            text="Create a list"
            Icon={ListIcon}
            handler={handleCreateListClick}
          />
        </StyledMenuButtonContainer>
        {showExitWarning && (
          <ConfirmationModal
            handleCancel={() => setShowExitWarning(false)}
            handleClickOutside={() => setShowExitWarning(false)}
            handleAccept={() => setIsExitConfirmed(true)}
            isWarning
            buttonText="Discard"
            titleText="Discard pick?"
            subTitle="Your edits and thoughts will be discarded if you leave before posting."
          />
        )}
      </Modal>
    </StyledWrap>
  );
};

export default AddSavedToList;
