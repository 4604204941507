import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import Avatars from 'components/UI/Avatars';
import InteractionBlock from 'components/InteractionBlock';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import Grain from 'components/UI/Grain';

import { CardSchema, UserObjectType } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import { useIsUserAllowedToReact, useItemUrl } from 'components/Card/helpers/hooks';
import { getListStyles } from 'components/Collection/helpers';
import getAuthUser from 'helpers/getAuthUser';
import usePageType from 'helpers/usePageType';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import { countedSizes } from '../SmallCard/helpers';
import { SMALL_CARD_HEIGHT, SIZE_RATIO } from '../SmallCard/constants';

import {
  StyledTitle,
  StyledReactionWrap,
  StyledHead,
  StyledWrap,
  StyledInner,
  StyledLink,
  StyledImagesList,
  StyledTextsList,
  StyledTextItem,
  StyledTextWrap,
  StyledLabel,
  StyledPrivateLabel,
  StyledAvatarsWrap,
  StyledCollaboratorsContainer,
  StyledComingSoonText,
} from './styled';
import { StyledDot } from '../BigCard/Card/styled';
import { getLineHeight } from './helpers';
import ImageItem from './ImageItem';

interface ISmallCardProps {
  item: CardSchema;
  dataTestId?: string;
  disableLink?: boolean;
  isReplace?: boolean;
}

const SmallCard: FC<ISmallCardProps> = ({ item, dataTestId, disableLink, isReplace }) => {
  const sizes = countedSizes(SMALL_CARD_HEIGHT, SIZE_RATIO);

  const [titleRef, setTitleRef] = useState<HTMLElement>();
  const [defaultFontSize, setDefaultFontSize] = useState<undefined | number>(undefined);
  const [numberOfTitleLines, setNumberOfTitleLines] = useState<number>(3);

  const { id, title, isCollaborative, creators, isPrivateList, hasNewCollaboratorsCards } = item;

  const filteredCreators =
    creators?.items.reduce((acc: UserObjectType[], creator) => (creator?.user ? acc.concat(creator.user) : acc), []) ??
    [];

  const {
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    isReactionsModalOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const isUserAllowedToReact = useIsUserAllowedToReact(creators?.items[0]?.user);
  const { thoughtId, setThoughtId, pickerId, setPickerId, cardId, setCardId } = useReactionContextState();
  const { isInvite, isMessages } = usePageType();
  const url = useItemUrl(id, true);
  const { userId } = getAuthUser();
  const { images, titles, bgColor, hasItems } = getListStyles(item);

  const isCurrentUserList = !!creators?.items?.find((data) => data?.user?.id === userId)?.user?.id;
  const isDisableReaction =
    isCurrentUserList || isCollaborative || isInvite || isMessages || !isUserAllowedToReact || disableLink;

  const titlesWithKey = useMemo(
    () =>
      titles.map((cover) => ({
        cover,
        key: v4(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [titles.length, titles.join(','), v4]
  );

  useEffect(() => {
    setCardId(id);
    setPickerId(creators?.items?.[0]?.user?.id ?? '');
  }, []);

  const setRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      setTitleRef(node);
      const height = node?.getBoundingClientRect().height;
      setNumberOfTitleLines(height ? Math.floor(height / getLineHeight(18)) : 3);
    }
  }, []);

  useEffect(() => {
    setDefaultFontSize(numberOfTitleLines === 1 ? 20 : 18);
  }, [numberOfTitleLines, titleRef]);

  const has3Lines = numberOfTitleLines === 3;

  return (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
      }}
    >
      <StyledReactionWrap data-testid={dataTestId} ref={infoRef} disableLinks={isQuickReactionOpen || disableLink}>
        <InteractionBlock
          tapCallback={() => setIsQuickReactionOpen(false)}
          longTapCallback={() => !isDisableReaction && setIsQuickReactionOpen(true)}
          getTapCoordinates={(x, y) => setReactionCoordinates({ x, y })}
        >
          <div>
            <StyledLink
              onClick={(e) => {
                if (disableLink) {
                  e.preventDefault();
                }
              }}
              className="card collection-preview-anchor"
              replace={isReplace}
              to={url}
            >
              <StyledWrap hasItems={hasItems} bgColor={bgColor}>
                <StyledInner>
                  <Grain />
                  <StyledHead>
                    {/* While V1, the date is not shown, we should use 3 lines for title truncation */}
                    <StyledTitle fontSize={defaultFontSize ?? 18} linesToTruncate={3} ref={setRef}>
                      {title}
                    </StyledTitle>
                  </StyledHead>
                  {!!images.length && (
                    <StyledImagesList
                      isMultiple={images.length >= 4}
                      className={`images-${images.length > 3 ? 3 : images.length}`}
                    >
                      {images.length === 1 && (
                        <ImageItem
                          dataChild={0}
                          src={images[0]?.images?.[0]?.url ?? ''}
                          imageRatio={images[0]?.images?.[0].aspectRatio ?? 1}
                          itemsLength={images.length}
                          has3lines={has3Lines}
                        />
                      )}
                      {images.length === 2 && (
                        <>
                          <ImageItem
                            dataChild={1}
                            has3lines={has3Lines}
                            itemsLength={images.length}
                            src={images[1]?.images?.[0]?.url ?? ''}
                            imageRatio={images[0]?.images?.[0].aspectRatio ?? 1}
                          />
                          <ImageItem
                            dataChild={2}
                            has3lines={has3Lines}
                            itemsLength={images.length}
                            src={images[0]?.images?.[0]?.url ?? ''}
                            imageRatio={images[0]?.images?.[0].aspectRatio ?? 1}
                          />
                        </>
                      )}
                      {images.length > 2 && (
                        <>
                          <ImageItem
                            dataChild={1}
                            has3lines={has3Lines}
                            itemsLength={images.length}
                            src={images[2]?.images?.[0]?.url ?? ''}
                            imageRatio={images[0]?.images?.[0].aspectRatio ?? 1}
                          />
                          <ImageItem
                            dataChild={2}
                            has3lines={has3Lines}
                            itemsLength={images.length}
                            src={images[0]?.images?.[0]?.url ?? ''}
                            imageRatio={images[0]?.images?.[0].aspectRatio ?? 1}
                          />
                          <ImageItem
                            dataChild={3}
                            has3lines={has3Lines}
                            itemsLength={images.length}
                            src={images[1]?.images?.[0]?.url ?? ''}
                            imageRatio={images[0]?.images?.[0].aspectRatio ?? 1}
                          />
                        </>
                      )}
                    </StyledImagesList>
                  )}
                  {!!titlesWithKey.length && (
                    <StyledTextWrap bgColor={bgColor}>
                      <StyledTextsList bgColor={bgColor} size={sizes.l}>
                        {titlesWithKey.map(({ cover, key }, i) => (
                          <StyledTextItem
                            className="cover-text-item"
                            key={key}
                            opacity={1 - (1 / titlesWithKey.length) * i}
                            color={COLORS.white[100]}
                          >
                            {cover}
                          </StyledTextItem>
                        ))}
                      </StyledTextsList>
                    </StyledTextWrap>
                  )}
                  {!titlesWithKey.length && !images.length && <StyledComingSoonText>Coming soon</StyledComingSoonText>}
                  {!!isPrivateList && <StyledLabel>Private</StyledLabel>}
                </StyledInner>

                {!!isCollaborative && (
                  <StyledCollaboratorsContainer>
                    {isPrivateList && <StyledPrivateLabel>Private</StyledPrivateLabel>}
                    <StyledAvatarsWrap>
                      <Avatars users={filteredCreators} limit={3} hideNumber />
                    </StyledAvatarsWrap>
                    {!!hasNewCollaboratorsCards && <StyledDot />}
                  </StyledCollaboratorsContainer>
                )}
                {!isCollaborative && isPrivateList && (
                  <StyledPrivateLabel isAbsolutelyPositioned>Private</StyledPrivateLabel>
                )}
              </StyledWrap>
            </StyledLink>
          </div>
        </InteractionBlock>
        {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && !isDisableReaction && (
          <ReactionMenu
            isShown={isQuickReactionOpen}
            reactionCoordinates={reactionCoordinates}
            handleClickOutside={() => setIsQuickReactionOpen(false)}
            minusTop={minusTop}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsQuickReactionOpen={setIsQuickReactionOpen}
          />
        )}
        {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
      </StyledReactionWrap>
    </ReactionsProvider>
  );
};

export default SmallCard;
