import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CardStyleSchema, ECardLayoutOptions, ETutorialStatus } from 'constants/graphqlTypes';
import { TEST_LEAVE_THOUGHT_INPUT } from 'constants/aqa/addPick';

import getAuthUser from 'helpers/getAuthUser';
import useDisableScroll from 'helpers/useDisableScroll';
import useGuideTour from 'helpers/useGuideTour';

import { useGetComments } from 'graphQL/card/comments/hooks';
import { useValidateThought } from 'graphQL/hiddenWords/hooks';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';

import Avatar from 'components/UI/Avatar';
import Card from 'components/Card';
import Modal from 'components/Modal';
import TextInput from 'components/UI/TextInput';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { SHARE_THOUGHT } from 'components/GuideTour/constants';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import Highlighter from 'components/Highlighter';
import OnboardingAlert from 'components/UI/OnboardingAlert';

import { COLORS } from 'styles/constants';
import { ROUTE_ADD_TO_LIST } from 'routes';
import Items from '../AddStyle/Items';
import { useAddContentLinks, useFinishAddContent } from '../helpers/hooks';
import { ListRoutingParams } from '../helpers/models';
import { INiceFindLocationStateVariable } from '../NiceFind/models';
import { useAddStyleLogic } from '../AddStyle/hooks';

import { StyledListTitle } from '../AddToList/styled';
import {
  StyledFooter,
  StyledComment,
  StyledStylesSectionWrap,
  StyledWrap,
  StyledModalWrap,
  StyledPreview,
} from './styled';

interface IAddThoughtProps {
  handleClose: () => void;
  listCardId?: string;
  listTitle: string;
  shouldRedirectToList?: boolean;
  listRoutingParams?: ListRoutingParams;
}

const SHARE_FIRST_THOUGHT = 'share-first-thought';

const AddStyleAndThought: FC<IAddThoughtProps> = ({
  handleClose,
  listCardId,
  listTitle,
  shouldRedirectToList,
  listRoutingParams,
}) => {
  const [thought, setThought] = useState('');
  const [error, setError] = useState('');
  const [hasHiddenWords, setHasHiddenWords] = useState(false);
  const [isDoneBtnLoading, setIsDoneButtonLoading] = useState(false);

  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  const [itemId, setItemId] = useState(0);

  const [isInputFocused, setIsInputFocused] = useState(false);

  const { userId } = getAuthUser();
  const { state } = useLocation<{ variable: INiceFindLocationStateVariable } | undefined>();
  const { variable: stateData } = state ?? {};
  const { card, userData, prevPath } = stateData ?? {};

  const id = card?.pickId ?? '';

  const isFromListSelection = prevPath === ROUTE_ADD_TO_LIST;
  const { niceFind, home, addToList } = useAddContentLinks();
  const { replace } = useHistory();

  const { comments } = useGetComments(id, { referrerUserId: userId });
  const myThought = comments?.items[0];
  const { text, id: thoughtId } = myThought ?? {};
  const refetch = useValidateThought();

  const { isGuideTour } = useGuideTour();
  const { isThoughtStepCompleted, loading: settingLoading, tutorialStatus } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({
    isThoughtStepCompleted: true,
  });

  const isDisplaySheet =
    !isGuideTour && !settingLoading && !isThoughtStepCompleted && tutorialStatus !== ETutorialStatus.NotStarted;

  useEffect(() => {
    if (text) {
      setThought(text);
    }
  }, [text]);

  useEffect(() => {
    if (!card) {
      replace(home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const [style, setStyle] = useState<CardStyleSchema | undefined>();

  const { isExtracting, currentColor, imageUrl, itemColors, updatedStyle, previewCard, items } = useAddStyleLogic({
    pickId: card?.pickId ?? '',
    cardId: card?.cardId ?? '',
    itemId,
    color: COLORS.transparent,
    setStyle,
  });

  useEffect(() => {
    setError('');
  }, [thought]);

  const handlePrev = () => {
    replace(isFromListSelection ? addToList : niceFind);
  };

  const { handleFinish } = useFinishAddContent({
    pickId: card?.pickId ?? '',
    cardId: card?.cardId ?? '',
    thought,
    isUpdateThought: !!thoughtId,
    listTitle: isFromListSelection ? listTitle : '',
    close: handleClose,
    listCardId: isFromListSelection ? listCardId : undefined,
    item: card && { ...card, ...(style && { cardStyle: style }) },
    onLockedError: () => setIsDoneButtonLoading(false),
    shouldRedirectToList,
    listRouteId: listRoutingParams?.id,
    isPrivateList: !!listRoutingParams?.isPrivateList,
    isSavedByMe: card?.isSavedByMe,
  });

  const validateThought = () => {
    refetch(thought).then((res) => {
      if (res && res?.data?.validateThought?.isSuspicious) {
        setHasHiddenWords(true);
      } else {
        handleFinish();
      }
    });
  };

  const handleDone = () => {
    validateThought();
    setIsDoneButtonLoading(true);
  };

  const confirmationHandler = () => {
    setHasHiddenWords(false);
    setIsDoneButtonLoading(false);
  };

  useDisableScroll(!isInputFocused);

  return (
    <StyledModalWrap onClick={(e) => setIsInputFocused(e.target instanceof HTMLTextAreaElement)}>
      <Modal
        secondaryText={isFromListSelection ? listTitle : undefined}
        fadeIn={false}
        handleClose={() => handlePrev()}
        secondaryHandler={isFromListSelection ? () => replace(addToList) : undefined}
        handleDone={handleDone}
        handlePrev={handlePrev}
        buttonText="Add"
        loading={isDoneBtnLoading}
        isExitConfirmed={isExitConfirmed}
        setShowConfirm={setShowExitWarning}
      >
        <StyledPreview
          bgColor={currentColor?.colorValue}
          hasNoImage={updatedStyle?.layout !== ECardLayoutOptions.Plain && !imageUrl && isExtracting}
        >
          {card && (
            <Card
              card={{ ...previewCard, cardStyle: updatedStyle, isSavedByMe: false }}
              disableLinks
              shouldDisableLongTap
            />
          )}
        </StyledPreview>
        <StyledFooter data-tour={SHARE_THOUGHT}>
          <StyledStylesSectionWrap isHidden={isInputFocused}>
            <StyledWrap>
              <StyledListTitle>Select your style</StyledListTitle>
            </StyledWrap>
            <Items items={items} itemId={itemId} setItemId={setItemId} />
          </StyledStylesSectionWrap>
          <StyledComment data-tour={SHARE_FIRST_THOUGHT}>
            {userData && <Avatar user={userData} />}
            <TextInput
              autoFocus={false}
              dataTestId={TEST_LEAVE_THOUGHT_INPUT}
              disabled={false}
              inputHeight={80}
              placeholder="Add your thought"
              setText={(value: string) => setThought(value)}
              text={thought}
              type="thought"
              error={error}
              isMultiLine
              autoCapitalize={EAutoCapitalize.On}
            />
          </StyledComment>
        </StyledFooter>
        {hasHiddenWords && (
          <ConfirmationModal
            handleCancel={handleFinish}
            handleAccept={confirmationHandler}
            handleClickOutside={confirmationHandler}
            subTitle="Help us create a respectful community by double-checking your thought for possible sensitive or harmful language."
            cancelButtonText="Post anyway"
            buttonText="Revise"
            titleText="Are you ready to share?"
            isWarning={false}
          />
        )}
        {showExitWarning && (
          <ConfirmationModal
            handleCancel={() => setShowExitWarning(false)}
            handleClickOutside={() => setShowExitWarning(false)}
            handleAccept={() => setIsExitConfirmed(true)}
            isWarning
            buttonText="Discard"
            titleText="Discard pick?"
            subTitle="Your edits and thoughts will be discarded if you leave before posting."
          />
        )}
        {isDisplaySheet && (
          <Highlighter
            id={SHARE_FIRST_THOUGHT}
            borderRadius="10px"
            highlighterOffset={10}
            elementOffset={18}
            highlight
            handleClose={updateUserSettings}
          >
            <OnboardingAlert title="Share your thoughts" submitText="Got it" handleSubmit={updateUserSettings}>
              Your perspective is important. It brings meaning and engages curiosity in others. Share what’s stirring
              inside.
            </OnboardingAlert>
          </Highlighter>
        )}
      </Modal>
    </StyledModalWrap>
  );
};

export default AddStyleAndThought;
