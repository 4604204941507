import React, { FC } from 'react';
import { StyledImageItem } from './styled';

interface IImageItemProps {
  src: string;
  imageRatio: number;
  itemsLength: number;
  has3lines: boolean;
  dataChild?: number;
}
const ImageItem: FC<IImageItemProps> = ({ src, imageRatio, itemsLength, has3lines, dataChild }) => {
  return (
    <StyledImageItem
      data-child={dataChild}
      src={src}
      imageRatio={imageRatio}
      itemsLength={itemsLength}
      has3lines={has3lines}
    />
  );
};

export default ImageItem;
