import { styled } from 'linaria/lib/react';
import { BREAKPOINTS, COLORS, FONTS } from 'styles/constants';
import { StyledTitle as StyledListTitle } from '../Content/styled';
import { StyledShare as ImportedStyledShare } from '../BigCard/Card/styled';

export const StyledContentCard = styled.div<{
  gradientStart?: string | null;
  gradientEnd?: string | null;
  isDark: boolean;
  bgColor?: string;
}>`
  background: ${({ gradientStart, isDark, bgColor }) =>
    bgColor ?? gradientStart ?? (isDark ? COLORS.black[100] : COLORS.white[100])};
  color: ${COLORS.white[100]};
  overflow: hidden;
  padding: 0px 12px 24px;
  position: relative;
  transition: 0.3s background ease 0.01s;

  &::before {
    background: ${({ gradientEnd }) =>
      `linear-gradient(${COLORS.transparent} 5%, ${gradientEnd ?? COLORS.black[60]} 100%)`};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: ${({ gradientStart, bgColor }) => (gradientStart && !bgColor ? '1' : '0')};
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    padding: 0 24px 24px;
    border-radius: 10px;
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 32px 12px 0px;
`;

export const StyledTitle = styled(StyledListTitle)<{ isSmall?: boolean }>`
  font: 28px / 130% ${FONTS.primary};
  max-width: 100%;
  padding: 0;

  &::after {
    top: -16px;
  }
`;

export const StyledDescription = styled.div<{ isDark: boolean }>`
  color: ${({ isDark }) => (isDark ? COLORS.white[100] : COLORS.brown.dark[100])};
  font: 400 14px/140% ${FONTS.secondary};
`;

export const StyledAvatarsContainer = styled.div`
  padding: 4px 0 0 8px;
`;

export const StyledImageContainer = styled.div<{ index: number }>`
  border-radius: 8px;
  left: ${({ index }) => index * 32}px;
  position: absolute;
  scale: 1.3;
  top: 0;
  transform-origin: top left;
  transform: ${({ index }) => `translateY(${index * 32}px)`};
  z-index: ${({ index }) => (index + 1) * 10}px;

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    scale: 1;
    transform: ${({ index }) => `translateY(${index * 64}px)`};
    left: ${({ index }) => index * 64}px;
  }
`;

export const StyledImage = styled.img<{ aspectRatio: number }>`
  border-radius: 8px;
  max-height: ${({ aspectRatio }) => {
    if (aspectRatio === 1) {
      return '195px';
    }
    return aspectRatio < 1 ? '245px' : 'none';
  }};
  max-width: ${({ aspectRatio }) => (aspectRatio > 1 ? '280px' : 'none')};

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: ${({ aspectRatio }) => {
      if (aspectRatio === 1) {
        return '390px';
      }
      return aspectRatio < 1 ? '490px' : 'none';
    }};
    max-width: ${({ aspectRatio }) => (aspectRatio > 1 ? '560px' : 'none')};
  }
`;

export const StyledShownImagesContainer = styled.div`
  display: flex;
  margin: 16px 0 0 20px;
  height: 245px;

  > div {
    position: relative;
    width: 100%;

    & > ${StyledImageContainer}:nth-last-child(3) {
      img {
        /* rotate is done here because transform origin is needed to be center */
        transform: rotate(-4deg);
      }
    }

    & > ${StyledImageContainer}:nth-last-child(2) {
      img {
        transform: rotate(4deg);
      }
    }

    & > ${StyledImageContainer}:nth-last-child(1) {
      img {
        transform: rotate(-2deg);
      }
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    height: 490px;
  }
`;

export const StyledShare = styled(ImportedStyledShare)`
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: ${COLORS.white[10]};
  border-radius: 50%;
  bottom: 28px;
  height: 44px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 24px;
  width: 44px;

  svg {
    align-items: center;
    height: 16px;
    justify-content: center;
    width: 16px;
  }
`;

export const StyledCollaboratorsContainer = styled.div`
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: ${COLORS.white[10]};
  border-radius: 22px;
  bottom: 28px;
  display: flex;
  font: 400 12px/140% ${FONTS.secondary};
  gap: 8px;
  left: 24px;
  padding: 10px 10px 10px 18px;
  position: absolute;
`;

export const StyledAvatarsWrap = styled.div`
  padding-left: 8px;
`;
export const StyledPrivateLabel = styled.div<{ isAbsolutelyPositioned?: boolean }>`
  background: linear-gradient(270deg, rgba(13, 127, 175, 0.3) 0%, rgba(190, 123, 74, 0.3) 100%),
    rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);
  border-radius: 999px;
  bottom: 38px;
  color: ${COLORS.brown.dark[100]};
  font: 400 12px/140% ${FONTS.secondary};
  left: 24px;
  padding: 4px 10px;
  position: ${({ isAbsolutelyPositioned }) => (isAbsolutelyPositioned ? 'absolute' : 'static')};
`;

export const StyledTextCard = styled.div<{ bgColor: string }>`
  color: ${COLORS.white[100]};
  padding: 0px 24px 30px;
  position: relative;
  background: ${({ bgColor }) => bgColor};

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    border-radius: 10px;
    padding-bottom: 40px;
  }
`;

export const StyledTextBlock = styled.div<{ bgColor: string }>`
  aspect-ratio: 1;
  background: ${({ bgColor }) => bgColor};
  border-radius: 6px;
  display: flex;
  margin: 21px auto 0;
  padding: 12px 12px 8px;
  width: 234px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  > ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); /* For WebKit browsers */
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    width: 368px;
  }
`;

export const StyledTextItem = styled.li`
  color: ${COLORS.white[100]};
  font: 400 16px/140% ${FONTS.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
`;
